// Font faces
//
// Instead of relying on the fonts that are available on a user's computer, you
// can use web fonts which, like images, are resources downloaded to the user's
// browser. Because of the bandwidth and rendering resources required, web fonts
// should be used with care.
//
// Numerous resources for web fonts can be found on Google. Here are a few
// websites where you can find Open Source fonts to download:
// - http://www.fontsquirrel.com/fontface
// - http://www.theleagueofmoveabletype.com
//
// In order to use these fonts, you will need to convert them into formats
// suitable for web fonts. We recommend the free-to-use Font Squirrel's
// Font-Face Generator:
//   http://www.fontsquirrel.com/fontface/generator
//
// The following is an example @font-face declaration. This font can then be
// used in any ruleset using a property like this:  font-family: Example, serif;
//
// Since we're using Sass, you'll need to declare your font faces here, then you
// can add them to the font variables in the _init.scss partial.

// @font-face {
//   font-family: 'Example';
//   src: url('../fonts/example.eot');
//   src: url('../fonts/example.eot?iefix') format('eot'),
//     url('../fonts/example.woff') format('woff'),
//     url('../fonts/example.ttf') format('truetype'),
//     url('../fonts/example.svg#webfontOkOndcij') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');

/* Fonts */
@font-face {
    font-family: 'new_baskerville_fsregular';
    src: url('../fonts/newbaskervillefs_regular_macroman/NewBaskerville-Regular-webfont.eot');
    src: url('../fonts/newbaskervillefs_regular_macroman/NewBaskerville-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/newbaskervillefs_regular_macroman/NewBaskerville-Regular-webfont.woff2') format('woff2'),
         url('../fonts/newbaskervillefs_regular_macroman/NewBaskerville-Regular-webfont.woff') format('woff'),
         url('../fonts/newbaskervillefs_regular_macroman/NewBaskerville-Regular-webfont.ttf') format('truetype'),
         url('../fonts/newbaskervillefs_regular_macroman/NewBaskerville-Regular-webfont.svg#new_baskerville_fsregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'new_baskerville_fsitalic';
    src: url('../fonts/newbaskervillefs_italic_macroman/NewBaskerville-It-webfont.eot');
    src: url('../fonts/newbaskervillefs_italic_macroman/NewBaskerville-It-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/newbaskervillefs_italic_macroman/NewBaskerville-It-webfont.woff2') format('woff2'),
         url('../fonts/newbaskervillefs_italic_macroman/NewBaskerville-It-webfont.woff') format('woff'),
         url('../fonts/newbaskervillefs_italic_macroman/NewBaskerville-It-webfont.ttf') format('truetype'),
         url('../fonts/newbaskervillefs_italic_macroman/NewBaskerville-It-webfont.svg#new_baskerville_fsitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'new_baskerville_fsbold_italic';
    src: url('../fonts/newbaskervillefs_bolditalic_macroman/NewBaskerville-BoldIt-webfont.eot');
    src: url('../fonts/newbaskervillefs_bolditalic_macroman/NewBaskerville-BoldIt-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/newbaskervillefs_bolditalic_macroman/NewBaskerville-BoldIt-webfont.woff2') format('woff2'),
         url('../fonts/newbaskervillefs_bolditalic_macroman/NewBaskerville-BoldIt-webfont.woff') format('woff'),
         url('../fonts/newbaskervillefs_bolditalic_macroman/NewBaskerville-BoldIt-webfont.ttf') format('truetype'),
         url('../fonts/newbaskervillefs_bolditalic_macroman/NewBaskerville-BoldIt-webfont.svg#new_baskerville_fsbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'encorpada_classicbold';
    src: url('../fonts/encorpadaclassic_bold_macroman/EncorpadaClassic-Bold-webfont.eot');
    src: url('../fonts/encorpadaclassic_bold_macroman/EncorpadaClassic-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/encorpadaclassic_bold_macroman/EncorpadaClassic-Bold-webfont.woff2') format('woff2'),
         url('../fonts/encorpadaclassic_bold_macroman/EncorpadaClassic-Bold-webfont.woff') format('woff'),
         url('../fonts/encorpadaclassic_bold_macroman/EncorpadaClassic-Bold-webfont.ttf') format('truetype'),
         url('../fonts/encorpadaclassic_bold_macroman/EncorpadaClassic-Bold-webfont.svg#encorpada_classicbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'encorpada_classicregular';
    src: url('../fonts/encorpadaclassic_regular_macroman/EncorpadaClassic-Regular-webfont.eot');
    src: url('../fonts/encorpadaclassic_regular_macroman/EncorpadaClassic-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/encorpadaclassic_regular_macroman/EncorpadaClassic-Regular-webfont.woff2') format('woff2'),
         url('../fonts/encorpadaclassic_regular_macroman/EncorpadaClassic-Regular-webfont.woff') format('woff'),
         url('../fonts/encorpadaclassic_regular_macroman/EncorpadaClassic-Regular-webfont.ttf') format('truetype'),
         url('../fonts/encorpadaclassic_regular_macroman/EncorpadaClassic-Regular-webfont.svg#encorpada_classicregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'news_gothic_fs_mediumbold';
    src: url('../fonts/newsgothicfs_bold_macroman/NewsGothic-Bold-webfont.eot');
    src: url('../fonts/newsgothicfs_bold_macroman/NewsGothic-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/newsgothicfs_bold_macroman/NewsGothic-Bold-webfont.woff2') format('woff2'),
         url('../fonts/newsgothicfs_bold_macroman/NewsGothic-Bold-webfont.woff') format('woff'),
         url('../fonts/newsgothicfs_bold_macroman/NewsGothic-Bold-webfont.ttf') format('truetype'),
         url('../fonts/newsgothicfs_bold_macroman/NewsGothic-Bold-webfont.svg#news_gothic_fs_mediumbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'news_gothic_fsbook';
    src: url('../fonts/newsgothicfs_book_macroman/NewsGothic-Book-webfont.eot');
    src: url('../fonts/newsgothicfs_book_macroman/NewsGothic-Book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/newsgothicfs_book_macroman/NewsGothic-Book-webfont.woff2') format('woff2'),
         url('../fonts/newsgothicfs_book_macroman/NewsGothic-Book-webfont.woff') format('woff'),
         url('../fonts/newsgothicfs_book_macroman/NewsGothic-Book-webfont.ttf') format('truetype'),
         url('../fonts/newsgothicfs_book_macroman/NewsGothic-Book-webfont.svg#news_gothic_fsbook') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'builtlight';
    src: url('../fonts/built_light_macroman/built_lt-webfont.eot');
    src: url('../fonts/built_light_macroman/built_lt-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/built_light_macroman/built_lt-webfont.woff2') format('woff2'),
         url('../fonts/built_light_macroman/built_lt-webfont.woff') format('woff'),
         url('../fonts/built_light_macroman/built_lt-webfont.ttf') format('truetype'),
         url('../fonts/built_light_macroman/built_lt-webfont.svg#builtlight') format('svg');
    font-weight: normal;
    font-style: normal;
}
