// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Breadcrumb navigation
//
// The path to the current page in the form of a list of links.
//
// Markup: breadcrumb.twig
//
// Style guide: navigation.breadcrumb

.breadcrumb,
%breadcrumb {

  // Set the font-size and line-height while keeping a proper vertical rhythm.
  @include type-layout(ms, 1);
  padding: 4em 0 0;
  font-family: $arial;

  @include zen-respond-to(xxxs) {
    padding: 2em $body-gutters 0;
  }
  @include zen-respond-to(s) {
    padding: 4em 0 0;
  }

  a {
    display: inline-block;

    text-decoration: none;
    color: color('blue');

    &:active,
    &:focus,
    &:hover {
      /*opacity: 0.5;*/
      color: color('black');
      // background: color(menu-bg-active);
    }
  }

  // @media print {
  //   //display: none;
  // }

  &__title {
    @extend %visually-hidden;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__separator {
    margin: 0 .3em;
  }
}
