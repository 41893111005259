// CKeditor specific styles
//
// //Markup: ckeditor.twig
//
// Style guide: base.ckeditor

%ckeditor,
.cke_editable {
  // Style ckeditor box like %main body section
  @extend %main;
  @extend %main-content-area;

  padding: 1em;
  background: color('white');
}

// Include main styles in Styles dropdown menu
.cke_panel_list {
  @extend %main;
  @extend %main-content-area;
}
