// Cross Sells
//
// Styles the leftside sidebar Cross Sell blocks
//
// //Markup: cross-sells.twig
//
// Style guide: navigation.cross-sells

.cross-sells,
%cross-sells {
  margin-bottom: 5em;

  /* Individual Cross Sells */
  div {
    //@include box-shadow();

    position: relative;
    overflow: hidden;

    min-height: 66px;
    margin: 0 0 2em;
    padding: 0;

    background: color('white');
    border: 1px solid color('border');

    @include zen-respond-to(s) {
      min-height: 38px;
    }
    @include zen-respond-to(l) {
      min-height: 66px;
    }

    img {
      float: left;
      width: 73px;
      height: 66px;
      overflow: hidden;
      background: color('blue');
      -webkit-print-color-adjust: exact;

      @include zen-respond-to(s) {
        width: 50px;
        height: auto;
      }
      @include zen-respond-to(l) {
        width: 73px;
        height: 66px;
      }
    }

    h4,
    span {
      @include type-layout(ml, 1);

      display: table-cell;
      vertical-align: middle;
      height: 66px;
      margin: 0;
      padding: 3px 3px 3px 1em;

      font-family: $open-sans;
      font-weight: normal;

      &.smaller {
        @include type-layout(m, 1);
      }

      @include zen-respond-to(s) {
        @include type-layout(m, 1);
        height: 44px;
      }
      @include zen-respond-to(l) {
        @include type-layout(ml, 1);
        height: 66px;
      }
    }

    a {
      display: block;
      text-decoration: none;
      background: color('white');

      &:focus,
      &:active,
      &:hover {
        opacity: .5;
      }
    }

    ul img {
      float: none;
      background: none;
    }
  }

  /* Taller Cross Sells */
  &-expanded div {
    height: auto;
    min-height: 187px;

    table {
      margin: 0;
      width: 100%;
      border-top: 1px solid color('border');

      tr td {
        width: 45%;
        padding: 15px 25px;
      }
    }

    h4,
    span {
      padding-bottom: 0;
    }
  }

  .cross-sell-contact,
  .cross-sell-search {
    min-height: auto;
    height: auto;
    background: color('grey-extra-light');
    color: color('grey-dark');

    font-family: $arial;

    form {
      margin: 0;
      padding: 1.3em;
      border: 0;
      background: color('grey-light');
    }

    legend,
    label {
      display: none;
    }

    fieldset {
      margin: 0;
      padding: 5px;

      background: color('white');

      border: 1px solid color('border');

      // box-shadow: inset 1px 1px 3px 0 rgba(125, 125, 125, .5);
      // -webkit-box-shadow: inset 1px 1px 3px 0 rgba(125, 125, 125, .5);
      // -moz-box-shadow: inset 1px 1px 3px 0 rgba(125, 125, 125, .5);
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: color('grey-dark');
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: color('grey-dark');
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: color('grey-dark');
    }

    input[type='text'] {
      border: 0;
      margin: 0 3px;
      padding: 2px;
      width: 88%;
      // max-width: 180px;
    }

    .search__submit {
      @include no-padding-margins;
      position: absolute;
      width: 15px;
      top: 25px; // 20px 28px 21px;
      right: 25px; // 20px 25px 20px;
      opacity: 1;

      .front & {
        top: 28px;
        right: 25px;

        top: 29px;
        right: 27px;
        width: auto;
        height: 23px;
      }

      &:focus,
      &:active,
      &:hover {
        opacity: .7;
      }
    }
  }
}
