// Dependencies.
// Allow layout-center's margin/padding to override layout-3col.
//@import 'layouts/layout-3col/layout-3col';

// Layout Content
//
// All other layouts should be nested inside this centered layout to ensure that
// content does not get too wide on very large screens.
//
// //Markup: layout-content.twig
//
// Weight: -1
//
// Style guide: layouts.layout-content

.layout-content,
%layout-content {
  @extend %clearfix;

  margin-left: 0; // $body-gutters;
  margin-right: 0; // $body-gutters;
  padding-top: 100px; // 130px; 25px;
  padding-bottom: 100px; // 50px;

  @include zen-respond-to('xxxs') {
    margin-left: 0;
    margin-right: 0;
    padding-top: $row-gutters * 2;
  }
  @include zen-respond-to('xs') {
    padding-top: $row-gutters * 3;
  }
  @include zen-respond-to('l') {
    padding-top: 50px; // 100px;
  }

  @media print {
    padding-top: 30px;
    padding-bottom: 0;
  }

  main {
    clear: both;
    float: left;

    width: $main-width; // 73.22%;
    margin-left: $main-margin-left; //26.77%;
    //max-width: 744px - $column-gutters; //744px;
    //margin-left: 272px; //272px;
    margin-right: -100%;

    &.layout-3col__left-content {
      margin-left: 0;
      margin-right: 0;
    }

    //width: 715px;

    .main__inner {
      // @include box-shadow();
      clear: both;
      // margin-right: 27px;
      padding: 0;
      // padding: 0 $main-inner-gutters 30px; /* width 680px     16px 27px 30px 28px; width = 660px */

      background: color('white');
      // border: 1px solid color('border');

      /*box-shadow: 0 0 3px 0 rgba(125, 125, 125, .5);
      -webkit-box-shadow: 0 0 3px 0 rgba(125, 125, 125, .5);
      -moz-box-shadow: 0 0 3px 0 rgba(125, 125, 125, .5);*/

      // @include zen-respond-to('xxxs') {
      //   padding: 0 15px 30px;
      // }
      // @include zen-respond-to('xs') {
      //   padding: 0 $main-inner-gutters 30px;
      // }

      .breadcrumb {
        // padding-top: 0;
        // margin-bottom: 3em;
        // border-bottom: 0;
        display: none;
      }
    }

    @include zen-respond-to(xxxs) {
      //background: #f00;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-left: $body-gutters;
      padding-right: $body-gutters;
      margin-bottom: 4em;
    }

    @include zen-respond-to(s) {
      //background: color('white');
      //max-width: 444px - $column-gutters; //744px;
      width: $main-width;
      margin-left: $main-margin-left;
      margin-right: -100%;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }

    @media print {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 4em;
    }

    // .no-sidebars &,
    // .layout-comprehensive & {
    .no-sidebars &,
    .layout-full-width & {
      width: 100%;
      margin-left: 0;
    }

    /*@include zen-respond-to(s) {
      background: #f00;

      max-width: 444px - $column-gutters; //744px;
      margin-left: 72px; //272px;
    }
    @include zen-respond-to(l) {
      background: #00f;

      max-width: 644px - $column-gutters; //744px;
      margin-left: 172px; //272px;
    }
    @include zen-respond-to(xl) {
      background: #f0f;

      max-width: 744px - $column-gutters; //744px;
      margin-left: 272px; //272px;
    }*/
  }

  aside {
    clear: right;
    float: right;

    width: $aside-width; //24.8%; // 26.77%;
    margin-right: $aside-margin-right; //75.19%; // 73.22%
    //max-width: 272px - $column-gutters;
    //margin-right: 744px;
    margin-left: -100%;

    @include zen-respond-to(xxxs) {
      clear: both;
      float: none;
      width: auto;
      margin-left: $body-gutters;
      margin-right: $body-gutters;
      margin-bottom: 1em;
    }
    @include zen-respond-to(s) {
      clear: right;
      float: right;
      //background: color('white');
      //max-width: 444px - $column-gutters; //744px;
      width: $aside-width;
      margin-left: -100%;
      margin-right: $aside-margin-right;
      margin-bottom: 0;
    }

    &.sidebar-second {
      margin-right: 0;
      margin-left: 0;
    }

    @media print {
      display: none;
    }

    .block-menu-block {
      @include no-padding-margins();

      //margin: 6px 0 0;
      border-top: 10px solid color('blue'); // 10px solid color('campaign-blue');

      h2.block__title {
        @include no-padding-margins();
        @include type-layout(l, 1);

        padding: 20px;

        background: color('off-white');
        border-left: 1px solid color('border');
        border-right: 1px solid color('border');

        font-family: $open-sans;
        font-weight: 400;

        a {
          display: block;
          text-decoration: none;
        }
      }
    }

    .menu {
      @include no-padding-margins();
      @include type-layout(ml, 1);

      margin: 0 0 5em;
      padding: 20px;

      border-top: 0;
      border-left: 1px solid color('border');
      border-right: 1px solid color('border');
      border-bottom: 1px solid color('border');
      // background: #fff;

      font-family: $open-sans; // $georgia;

      @include zen-respond-to(xxxs) {
        @include type-layout(ml, 1);
      }
      @include zen-respond-to(s) {
        @include type-layout(m, .8);
      }
      @include zen-respond-to(xl) {
        @include type-layout(ml, 1);
      }

      /*@include zen-respond-to(xxxs) {
        @include type-layout(m, 1);
        background: color('white');
        border: 1px solid color('border');
      }
      @include zen-respond-to(s) {
        @include type-layout(ml, 1);
        background: none;
        border: 0;
      }*/

      &__item {
        border-bottom: 1px solid color('border');

        /*@include zen-respond-to(xxxs) {
          border-bottom: 0;
        }
        @include zen-respond-to(s) {
          border-bottom: 1px solid color('border');
        }*/

        &.last,
        &:last-child {
          border-bottom: 0;
        }
      }

      a {
        display: block;
        // padding: .7em 0 .7em .5em;
        padding: 1em 0;

        text-decoration: none;

        color: color('link');

        /*@include zen-respond-to(xxxs) {
          padding: 1em 1em;
        }
        @include zen-respond-to(s) {
          padding: .7em 0 .7em .5em;
        }*/

        &:active,
        &:focus,
        &:hover {
          color: color('black');
          background: color('white');
        }

        &.active {
          font-weight: bold;
        }
      }

      .menu {
        @include no-padding-margins();
        // @include type-layout(ms, .7);

        margin: 0;
        padding: .5em 20px; // .8em 25px;

        // font-family: $arial;

        background: color('white');
        border-top: 1px solid color('border');
        border-right: 0;
        border-bottom: 0;
        border-left: 0;

        .menu {
          margin-top: 0;
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 0;
        }

        // a {
        //   text-decoration: none;

        //   color: color('link');

        //   &.active,
        //   &:active,
        //   &:focus,
        //   &:hover {
        //     color: color('black');
        //   }
        // }
      }
    }

    // Only style top level "always expanded" items differently
    .menu-level-1 > .menu {
      > .menu__item.is-expanded.expanded > a {
        font-weight: bold;
        color: color('text');

        &:focus,
        &:hover {
          opacity: .8;
        }
      }
    }

    .search-box {
      margin-left: 0;
      margin-right: 0;

      // @include zen-respond-to(xxxs) {
      //   margin-left: -$body-gutters;
      //   margin-right: -$body-gutters;
      // }
      // @include zen-respond-to(s) {
      //   margin-left: 0;
      //   margin-right: 0;
      // }
    }
  }

  .region-highlighted {
    //border: 1px solid color('border');

    /*clear: both;
    float: left;

    width: $main-width; // 73.22%;
    margin-left: $main-margin-left; //26.77%;
    margin-right: -100%;*/

    padding-bottom: 2 * $body-gutters;
  }
}
