// Mixins - Custom
//
// Custom mixins for mesacc
//
// Style guide: sass.mixins-custom


// no-padding-margins
//
// Remove all padding and margins for an element.
//
// Style guide: sass.mixins-custom.no-padding-margins
@mixin no-padding-margins {
  margin: 0;
  padding: 0;
}

// @mixin box-shadow($depth: 3px) {
//   // box-shadow: 0 0 $depth 0 rgba(125, 125, 125, .5);
//   // -webkit-box-shadow: 0 0 $depth 0 rgba(125, 125, 125, .5);
//   // -moz-box-shadow: 0 0 $depth 0 rgba(125, 125, 125,
// }

@mixin box-shadow-inset($depth: 3px) {
  border-top: 1px solid color('grey-light');
  border-left: 1px solid color('grey-light');
  border-bottom: 1px solid color('grey-extra-light');
  border-right: 1px solid color('grey-extra-light');

  box-shadow: inset 1px 1px $depth 0 rgba(125, 125, 125, .5);
  -webkit-box-shadow: inset 1px 1px $depth 0 rgba(125, 125, 125, .5);
  -moz-box-shadow: inset 1px 1px $depth 0 rgba(125, 125, 125, .5);
}

// transition-default()
//
// Set default transition method for any attached element,
// and transition all properties by default 
//
// Style guide: sass.mixins-custom.transition-default
@mixin transition-default($value: 200ms) {
  -webkit-transition: $value ease-in-out;
  -moz-transition: $value ease-in-out;
  -o-transition: $value ease-in-out;
  transition: $value ease-in-out;
  backface-visibility: hidden;
}

// grayscale-element()
//
// Use CSS filters to make certain elements grayscale
//
// Style guide: sass.mixins-custom.grayscale-element
@mixin grayscale-element($value) {
  -webkit-filter: #{'grayscale(#{$value})'};
  //-moz-filter: #{'grayscale(#{$value})'};
  filter: #{'grayscale(#{$value})'};
}
