// Footer area and blocks
//
// //Markup: footer.twig
//
// Style guide: components.footer

footer {
  @include no-padding-margins();
  @include type-layout(ms, 1);

  font-family: $open-sans;

  color: color('white');
  background: color('darkest-blue');

  overflow: auto;

  @media print {
    display: none;
  }

  .block {
    @include no-padding-margins();
    max-width: $body-width; // $body-true-width;
    margin: auto;
    padding: 1em $body-gutters;
  }

  h3 {
    @include type-layout(ml, 1.5);
    margin-bottom: .5em;

    font-family: $open-sans; // $georgia;

    color: color('white');
    // text-transform: uppercase;
    border-bottom: 1px solid color('border-footer');
  }

  h4 {
    @include type-layout(m, 1);
    margin-top: 0;
    margin-bottom: 0;
  }

  a,
  :visited {
    text-decoration: none;
    color: color('white');

    &:active,
    &:focus,
    &:hover {
      color: color('light-blue');
    }
  }

  .footer__locations {
    float: left;
    width: 73%;
    margin-right: $body-gutters;
    padding-bottom: 6em;

    @include zen-respond-to('xxxs') {
      width: 100%;
      margin-right: 0;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 2em;
    }

    @include zen-respond-to('s') {
      width: 73%;
      margin-right: $body-gutters;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 6em;
    }

    .sd,
    .rm,
    .other {
      float: left;
      width: 32%;
      margin-right: 2%;
      margin-bottom: 5%;
      padding-top: .5em;

      img {
        width: 100%;
      }
    }

    .other {
      margin-right: 0;

      div {
        margin-bottom: 2em;
      }
    }

    @include zen-respond-to('xxxs') {
      .sd,
      .rm,
      .other div {
        float: left;
        width: 49%;
        margin-right: 0%;
      }

      .sd,
      .other .dt {
        margin-right: 2%;
      }

      .other {
        width: 100%;
        margin-right: 0;
      }
    }

    @include zen-respond-to('s') {
      .sd,
      .rm,
      .other {
        width: 32%;
        margin-right: 2%;
      }

      .other {
        margin-right: 0;

          div {
            float: none;
            width: auto;
        }
      }
    }

    .footer__location-image {
      @include transition-default();

      display: block;
      margin-bottom: .5em;

      &:focus,
      &:hover {
        opacity: .5;
      }
    }

    .footer__location-map {
      color: color('light-blue');

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer__social {
    float: right;
    width: 25%;

    @include zen-respond-to('xxxs') {
      float: none;
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
    }

    @include zen-respond-to('s') {
      float: right;
      width: 25%;
      padding: 0;
    }

    ul {
      @include no-padding-margins();

      li {
        @include no-padding-margins();
        list-style: none;

        border-bottom: 1px solid color('border-footer');

        background: url('../images/icons/icon-facebook-footer.png') 5px 6px no-repeat;

        &.facebook {
          background-image: url('../images/icons/icon-facebook-footer.png');
        }

        &.x {
          background-image: url('../images/icons/icon-x-footer.png');
        }

        &.twitter {
          background-image: url('../images/icons/icon-twitter-footer.png');
        }

        &.instagram {
          background-image: url('../images/icons/icon-instagram-footer.png');
        }

        &.youtube {
          background-image: url('../images/icons/icon-youtube-footer.png');
        }

        &.snapchat {
          background-image: url('../images/icons/icon-snapchat-footer.png');
        }

        &.linkedin {
          background-image: url('../images/icons/icon-linkedin-footer.png');
        }

        a {
          display: block;
          padding: 7px 5px 7px 35px;
        }
      }
    }
  }

  .footer__links {
    @include no-padding-margins();
    @include type-layout(m, 1);

    clear: both;
    overflow: auto;

    border-top: 1px solid color('border-footer');
    border-bottom: 1px solid color('border-footer');

    li {
      list-style: none;
      float: left;

      a {
        display: block;
        padding: 1em;
      }

      &.login {
        clear: left;
        float: right;
      }

      // @include zen-respond-to('xxxs') {
      //   //
      // }

      @include zen-respond-to('xs') {
        &.first a {
          padding-left: 0;
        }

        &.last a {
          padding-right: 0;
        }
      }
    }
  }

  .thunderbirds {
    text-align: center;
    margin: 2em auto;

    img {
      max-width: 250px;
    }

    a:focus,
    a:hover {
      opacity: .5;
    }
  }

  .footer__app {
    margin: 0 auto 2em;
    overflow: auto;

    .icons {
      text-align: center;

      svg {
        float: left;
        width: 50%;
        fill: color('white');
      }
    }

    a:focus,
    a:hover {
      opacity: .5;
    }

    span {
      display: none;
    }
  }

  .mcccd {
    width: 25%;
    clear: both;
    float: right;
    text-align: center;
    margin: 3em 0 3em 3em;

    @include zen-respond-to('xxxs') {
      width: auto;
      float: none;
    }
    @include zen-respond-to('xl') {
      width: 25%;
      float: right;
    }

    img {
      max-width: 250px;
    }

    a:focus,
    a:hover {
      opacity: .5;
    }
  }

  .footer__copyright {
    @include type-layout(s, .75);
  }

  .footer__schema {
    display: none;
  }
}
