// Sidebar Blocks
//
// Miscellaneous blocks and navigation structures found
// in the sidebar areas, usually in the <aside>
//
// //Markup: sidebar-blocks.twig
//
// Style guide: navigation.sidebar-blocks

/* Mesa Legend - Cross Sell Feed */
.feed-legend {

  margin-bottom: 5em;

  .cross-sells {
    margin-bottom: 0;

    div {
      margin-bottom: 0;
    }
  }

  ul {
    @include no-padding-margins;
    //@include box-shadow();
    @include type-layout(ms, .75);

    padding: 1em 1.5em 0;
    border: 1px solid color('border');
    border-top: 0;

    font-family: $arial;

    background: color('white');

    li {
      list-style: none;

      a {
        text-decoration: none;
      }
    }
  }

  .view-footer {
    @include type-layout(xs, .5);
    //@include box-shadow();

    margin: 0;
    padding: 1em 1.5em;
    background: color('off-white');
    border: 1px solid color('border');

    font-family: $arial;
  }
}

/* Sidebar feeds */
/* News & Events */
aside .feed-twitter,
aside .feed-facebook,
.feed-twitter-callout,
.feed-facebook-callout {
  margin: -1.5em 0 4em;

  ul,
  .fb-page {
    @include type-layout(ms, .75);
    //@include box-shadow();

    font-family: $arial;
    list-style: none;

    margin: 0;
    padding: 2em 2em .5em;

    background: color('white');

    border-top: 1px solid color('border');
    border-left: 1px solid color('border');
    border-right: 1px solid color('border');

    li {
      margin-bottom: 1.5em;
      overflow: hidden;

      p a {
        color: color('link');
        text-decoration: underline;

        &:focus,
        &:hover {
          opacity: .5;
        }
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;

    a {
      text-decoration: underline;
    }
  }

  .timestamp {
    margin-top: 0;
    font-weight: bold;
    color: color('blue');

    a {
      text-decoration: none;
    }
  }

  h3 {
    @include type-layout(ms, .75);
    display: block;
    height: 45px;
    margin: 0;
    padding: 0;

    clear: both;

    font-family: $georgia;
    text-transform: uppercase;
    font-weight: normal;

    a {
      display: block;
      margin: 0;
      padding: 23px 0 0 96px;
      text-decoration: none;
      color: color('text');

      &:focus,
      &:active,
      &:hover {
        color: color('blue');
      }
    }
  }

  h4 {
    @include type-layout(m, 1);
    margin-top: 0;
    margin-bottom: 0;
    line-height: 100%;
    color: color('blue');

    & + p {
      margin-top: 0;
    }
  }

  img {
    float: left;
    margin-right: .5em;
  }

  &.feed-twitter h3,
  &.feed-twitter-callout h3 {
    background: url('../images/bg-feed-twitter.png') 0 0 no-repeat;
  }

  &.feed-facebook,
  &.feed-facebook-callout {
    h3 {
      background: url('../images/bg-feed-facebook.png') 0 0 no-repeat;
    }

    a p {
      color: color('text');
    }

    a {
      display: block;
      text-decoration: none;

      &:focus,
      &:hover {
        opacity: .5;
      }
    }
  }
}

/* Cross Sells - ads */
aside .block {

  .featured-events,
  .block-ad {
    //@include box-shadow();
    position: relative;
    overflow: hidden;

    margin: 0 0 3em;
    padding: 0 0 1em;

    font-family: $arial;

    border: 1px solid color('border');
    background: color('white');

    img {
      width: 100%;
      height: auto !important;
    }

    h3 {
      @include no-padding-margins();
      @include type-layout(m, 1);
      text-transform: uppercase;

      a {
        display: block;
        padding: 1.3em;

        color: color('text');
        text-decoration: none;

        &:focus,
        &:active,
        &:hover {
          color: color('blue');
        }
      }
    }

    h4 {
      @include type-layout(ml, 1);
      margin: 0;
      font-weight: normal;

      a {
        display: block;
        margin: 0;
        padding: .7em 1em;
        text-decoration: none;
      }
    }

    p,
    .event-date,
    .date-display-range {
      @include type-layout(s, .66);
      margin: 0;
      padding: 0 1.5em .5em;

      strong {
        font-weight: normal;
      }
    }

    a:hover img {
      opacity: .7;
    }
  }

  .contact-ad {
    position: relative;
    overflow: hidden;

    margin: 0 0 3em;
    padding: 3em;

    font-family: $arial;

    color: color('white');

    background: color('blue');

    h3 {
      @include no-padding-margins();
      @include type-layout(xl, 1.25);

      a {
        color: color('white');
        text-decoration: none;

        &:focus,
        &:hover {
          opacity: .8;
        }
      }
    }

    p {
      @include type-layout(ml, 1.25);
    }

    .call-to-action a {
      margin-top: 2em;
      margin-bottom: 1em;
      background-color: color('white');
      color: color('blue');
      text-align: center;
    }

    @include zen-respond-to(xxxs) {
      padding: 3em;

      h3 {
        @include type-layout(xl, 1.25);
        margin-bottom: 1em;
      }

      p {
        @include type-layout(ml, 1.25);
      }

      .call-to-action a {
        padding-left: 2em;
        padding-right: 2em;
      }
    }
    @include zen-respond-to(s) {
      padding: 2em;

      h3 {
        @include type-layout(l, 1.25);
        margin-bottom: 1em;
      }

      p {
        @include type-layout(m, 1);
      }

      .call-to-action a {
        padding-left: 0;
        padding-right: 0;
      }
    }
    @include zen-respond-to(xxl) {
      padding: 3em;

      h3 {
        @include type-layout(xl, 1.25);
        margin-bottom: 1em;
      }

      p {
        @include type-layout(ml, 1.25);
      }

      .call-to-action a {
        padding-left: 2em;
        padding-right: 2em;
      }
    }
  }
}

aside .messages {
  padding: 1em 2em;
  font-family: $arial;
}

/* Custom Block ads */
#block-block-72,
#block-block-76 {
  margin: 0 0 5em;

  .block-ad {
    padding-bottom: 1.5em;
  }

  img {
    float: left;
    width: 100%;
  }

  h3 {
    @include type-layout(ml, 1);
    clear: left;

    text-transform: none;

    a {
      color: color('black');
      padding: 1em;
      background: rgba(252, 207, 25, 1);

      &:focus,
      &:active,
      &:hover {
        opacity: .7;
      }
    }
  }

  p {
    @include type-layout(m, 1);
    padding: 1.3em 1.3em 0;
  }
}

/* Ad - Report to Our Community */
#block-block-72 {

  h3 a {
    color: color('white');
    background: rgba(14, 87, 156, 1);
  }
}

/* Ad - The Arts at MCC */
#block-block-76 {

  h3 a {
    color: color('black');
    background: rgba(252, 207, 25, 1);
  }
}
