// Dependencies.
@import 'components/clearfix/clearfix';

// Tabs
//
// The primary and secondary tabs.
//
// Markup: tabs.twig
//
// .tabs--secondary - Secondary tabs
//
// Style guide: navigation.tabs

// Tabs (stacked)
//
// When the secondary tabs appear directly after the primary tabs, the styling
// is slightly different.
//
// Markup: tabs--stacked.twig
//
// Style guide: navigation.tabs.stacked

.tabs,
%tabs {
  @extend %clearfix;

  // The line height of a tab.
  $tab-height: 1.25;

  @include margin((2 - $tab-height) 0 0);
  //@include line-height($tab-height);
  @if support-for(ie, 9) {
    // IE 9 and earlier don't understand gradients.
    border-bottom: 1px solid color(tabs-border) \0/ie;
  }
  background-image: linear-gradient(to top, color(tabs-border) 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  //white-space: nowrap;

  // @media print {
  //   display: none;
  // }

  &__tab {
    float: left;
    margin: 0 3px;
    border: 1px solid color(tabs-border);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // Allow the tabs gradient to appear just below the tab.
    border-bottom-color: transparent;
    @if support-for(ie, 9) {
      // IE 9 and earlier don't understand gradients.
      border-bottom: 0 \0/ie;
    }
    // Clip the background colors on tab links.
    overflow: hidden;
    background: color(tab-bg);

    @include rtl() {
      float: right;
    }

    &.is-active {
      // Cover the tabs gradient with the same color as the background.
      border-bottom-color: color(tabs-bg);
    }
  }

  &__tab-link {
    @include padding(.2 1);
    display: block;
    text-decoration: none;
    transition: color .3s, background .3s, border .3s;
    text-shadow: color(tab-text-shadow) 0 1px 0;

    // Undo default transition mixin
    backface-visibility: visible;

    color: color(tab);
    background: color(tab-bg);
    letter-spacing: 1px;

    &:focus,
    &:hover {
      background: lighten(color(tab-bg), 5%);
    }

    &:active,
    &.is-active,
    &--is-active {
      background: color(tabs-bg);
      text-shadow: none;
    }
  }

  // Turns off .tabs styling.
  &--off {
    @include margin(0);
    @if support-for(ie, 9) {
      border-bottom: 0;
    }
    padding: 0;
    background-image: none;
  }

  &--secondary {
    @include margin-top(1);
    @include font-size(s);
    background-image: none;
  }

  // When secondary tabs appear directly after primary tabs.
  & + &--secondary {
    @include margin-top(0);
    background-image: linear-gradient(to top, color(tabs-border) 1px, transparent 1px);
  }

  &--secondary &__tab {
    @include margin((2 - $tab-height)/2 3px);
    border: 0;
    background: transparent;

    @include rtl() {
      float: right;
    }

    &.is-active {
      border-bottom-color: transparent;
    }
  }

  &--secondary &__tab-link {
    border: 1px solid color(tabs-border);
    border-radius: $base-line-height;
    color: color(tab-secondary);
    background: lighten(color(tab-bg), 8%);
    letter-spacing: normal;

    &:focus,
    &:hover {
      color: darken(color(tab-secondary), 20%);
      background: color(tab-bg);
      border-color: darken(color(tab-bg), 26.5%);
    }

    &:active,
    &.is-active,
    &--is-active {
      color: lighten(color(tab-bg), 15%);
      text-shadow: darken(color(tab-secondary), 20%) 0 1px 0;
      background: color(tab-secondary);
      border-color: darken(color(tab-secondary), 40%);
    }
  }
}

%tabs-content,
.tabs-content {

  // @include zen-respond-to(xxxs) {
  //   margin-left: -$main-inner-gutters/2;
  //   margin-right: -$main-inner-gutters/2;
  // }
  // @include zen-respond-to(xs) {
  //   margin-left: auto;
  //   margin-right: auto;
  // }

  &.ui-widget {
    font-family: inherit;
    color: inherit;
  }

  &.tabs {
    padding: 0;
    border: 0;

    background: none;
  }

  .ui-tabs-nav {
    padding: 0;
    border: 0;
    border-bottom: 1px solid color('border');
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: none;

    @media print {
      border-color: color('border-print');
    }

    li {
      @include type-layout(m, 1);
      font-family: $arial;
      margin-right: .5em;
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      font-weight: bold;

      a {
        padding-left: 2em;
        padding-right: 2em;
        transition: background .3s, border .3s;
      }

      &.ui-state-default {
        border: 0;
        //border-top: 1px solid color('gradient-blue-top');
        border-right: 1px solid color('gradient-blue-top');
        border-left: 1px solid color('gradient-blue-top');

        @media print {
          border: 0;
          background: none;

          font-weight: normal;
        }

        a {
          color: color('white');
          text-shadow: 0 -1px 0 color('grey-darkest');
          background: color('gradient-blue-bottom');
          // background: -webkit-linear-gradient(bottom, color('gradient-blue-top'), color('gradient-blue-bottom'), color('gradient-blue-bottom'));
          // background: -o-linear-gradient(bottom, color('gradient-blue-top'), color('gradient-blue-bottom'), color('gradient-blue-bottom'));
          // background: -moz-linear-gradient(bottom, color('gradient-blue-top'), color('gradient-blue-bottom'), color('gradient-blue-bottom'));
          // background: linear-gradient(to bottom, color('gradient-blue-top'), color('gradient-blue-bottom'), color('gradient-blue-bottom'));

          &:focus,
          &:active,
          &:hover {
            background: color('gradient-blue-bottom-hover');
            // background: -webkit-linear-gradient(bottom, color('gradient-blue-top-hover'), color('gradient-blue-bottom-hover'), color('gradient-blue-bottom-hover'));
            // background: -o-linear-gradient(bottom, color('gradient-blue-top-hover'), color('gradient-blue-bottom-hover'), color('gradient-blue-bottom-hover'));
            // background: -moz-linear-gradient(bottom, color('gradient-blue-top-hover'), color('gradient-blue-bottom-hover'), color('gradient-blue-bottom-hover'));
            // background: linear-gradient(to bottom, color('gradient-blue-top-hover'), color('gradient-blue-bottom-hover'), color('gradient-blue-bottom-hover'));
          }

          @media print {
            color: color('text-lighter');
            text-shadow: none;
            background: none;
          }
        }

        &:first-child {
          border-left: 0;
        }
      }

      &.ui-state-active,
      &.ui-tabs-active {
        padding-bottom: 0;
        border-top: 1px solid color('border');
        border-right: 1px solid color('border');
        border-left: 1px solid color('border');

        @media print {
          font-weight: 700;
          border-color: color('border-print');
        }

        a {
          color: color('text');
          text-shadow: none;
          background: color('off-white');

          &:focus,
          &:active,
          &:hover {
            color: color('black');
            background: color('off-white');
          }

          @media print {
            color: color('black');
            background: none;
          }
        }

        &:first-child {
          border-left: 0;

          @media print {
            border-left: 1px solid color('border-print');
          }
        }
      }
    }
  }

  .ui-widget-content {
    @include type-layout(m, 1);
    overflow: auto;
    font-family: inherit;
    color: inherit;
    background: color('off-white');

    @media print {
      padding-left: 0;
      padding-right: 0;
      background: none;
      overflow: visible;
    }

    p a,
    li a,
    td a,
    th a {
      color: color(link);
    }

    p :visited,
    li :visited,
    td :visited,
    th :visited {
      color: color(link-visited);
    }
  }
}

/* Stepped tabs */
%steps,
.steps {
  @extend %tabs-content;
  background: color('white');
}

//
// Drupal selectors.
//

// Views mis-uses the theme hooks for tabs.
.views-displays .secondary {
  @extend %tabs--off;
}
