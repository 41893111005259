// Main area and content blocks
//
// //Markup: main.twig
//
// Style guide: components.main

%main,
main {

  /* Default HTML elements */

  /* Debatable style */
  a {
    // text-decoration: none;

    &:focus,
    &:active,
    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    color: color('black');

    strong {
      font-weight: 600;
    }
  }

  h1 {
    margin-top: 0;
    font-family: $open-sans;
    font-weight: 300;
    color: color('campaign-blue');

    @include zen-respond-to(xxxs) {
      margin-bottom: 1.5rem;
    }
    @include zen-respond-to(xs) {
      margin-bottom: 3.84615rem;
    }

    @media print {
      @include type-layout(xxl, 1.5);
      margin-bottom: 2rem;
    }
  }

  h2 {
    clear: left;
    border-bottom: 1px solid color('border');

    @media print {
      border-color: color('border-print');
    }

    &.no-clear {
      clear: none;
    }

    &.float-clear {
      clear: both;
    }

    // &:first-child,
    // &:first-of-type,
    .contact-info + &,
    &.first,
    &.top {
      margin-top: .5em;
    }

    .contact-info &,
    .callout &,
    h2:first-child + & {
      margin-top: 1em;
    }

    &.tagline {
      margin-top: .5em;
      margin-bottom: 1em;
      font-style: italic;
      color: color('grey-dark');
    }

    a {
      text-decoration: none;
    }
  }

  h3 {
    /*&:first-child,
    &:first-of-type,*/
    .contact-info + &,
    &.first {
      margin-top: .5em;
    }

    &.tagline {
      margin-top: 0;
      color: color('grey-dark');
      font-style: italic;
    }
  }

  h4 {
    a {
      font-weight: 600;
    }
  }

  ul,
  ol {

   li {
     margin-top: .5em;
     margin-bottom: .5em;
   }
  }

  /* Tables */
  table {
    @include no-padding-margins();

    margin-bottom: 1em;

    font-family: $arial;

    @include zen-respond-to(xxxs) {
      font-size: 90%;
    }
    @include zen-respond-to(xs) {
      font-size: 100%;
    }

    tr {
      background: color('white');

      &.even {
        background: color('off-white');
      }

      &.labels,
      &.labels th {
        font-weight: bold;
        background: color('off-white-dark');
      }

      &.section-break td {
        @include type-layout(ml, 1);
        font-weight: bold;
        background: color('grey-light');
      }
    }

    th,
    td {
      padding: .5em .7em;
      vertical-align: top;
      text-align: left;
      font-weight: weight(normal);

      .smaller {
        display: inline-block;
        color: color('grey-dark');
        line-height: 125%;
      }
    }

    thead {
      th,
      td,
      th.header,
      td.header {
        color: color('white');
        background: color('darkest-blue');
        font-weight: weight(bold);

        h2,
        h3,
        h4,
        h5 {
          color: color('white');
        }

        a,
        a:visited {
          text-decoration: none;
          color: color('light-blue');

          &:active,
          &:focus,
          &:hover {
            color: color('white');
          }
        }

        @media print {
          color: color('black');
          background: color('white');
          font-weight: 700;
          border: 1px solid color('border-print');
        }
      }
    }

    tbody {
      th,
      td {
        border-right: 1px solid color('grey-light');
        border-bottom: 1px solid color('grey-light');

        @media print {
          border: 1px solid color('border-print');
        }

        &:last-child {
          border-right: 0;

          @media print {
            border-right: 1px solid color('border-print');
          }
        }

        a {
          text-decoration: none;
        }

        p a {
          text-decoration: underline;
        }
      }

      th {
        background: color('off-white');
      }

      thead th {
      	background: color('grey');
      	font-weight: 400;
      }
    }

    tfoot {
      th,
      td {
        @include type-layout(s, 1);
        background: color('off-white-dark');
      }
    }

    th.mobile-hide,
    td.mobile-hide {
      display: table-cell;

      @include zen-respond-to(xxxs) {
        display: none;
      }
      @include zen-respond-to(xs) {
        display: table-cell;
      }
    }

    caption {
      padding: 1em;
      font-style: italic;
    }

    &.zebra-table tbody tr:nth-child(even) {
      background: color('off-white');

      tr.section-break {
        background: none;
      }
    }

    &.smaller-table {
      @include type-layout(m, 1);
    }

    &.slim-width {
      width: auto;
    }

    &.no-border td {
      border-bottom: 0;
      border-right: 0;
    }

    td.border-bottom,
    &.no-border .border-bottom {
      border-bottom: 1px solid color('grey-light');
    }

    .hide-caption {
      caption {
        display: none;
      }
    }

    .open {
      text-align: center;
      font-weight: bold;
      color: color('open-font');
      background-color: color('open');
    }

    .closed {
      text-align: center;
      font-weight: bold;
      color: color('closed-font');
      background-color: color('closed');
    }

    .numeric,
    .date {
      text-align: right;
    }

    .center,
    .centered {
      text-align: center;
    }

    /* Allow for slightly wider columns when necessary */
    .width-33 {
      width: 30%;
    }

    h2:first-of-type,
    h3:first-of-type,
    h4:first-of-type,
    h5:first-of-type {
      margin-top: .5em;
    }

    p {
      margin: 0 0 .5em;

      a {
        text-decoration: underline;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin: 0;
      padding-left: 1em;

      li {
        margin-left: 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  fieldset {
    background: color('white');
  }

  cite,
  .citation {
    @include type-layout(s, 1);
    display: inline-block;
    margin-top: 3em;
    color: color('grey-dark');
  }

  .messages {
    /* Because text is blue, add underlines for for links in default messages */
    a {
      text-decoration: underline;
    }

    &.warning,
    &.error,
    .call-to-action {
      a {
        text-decoration: none;
      }
    }
  }


  /* Classes / Blocks */
}

%main-content-area,
%main .content__area,
main .content__area {
  @include type-layout('ml', 1.25);
  outline: none;
  font-family: $open-sans;

  // Bold all internal copy links. Problematic.
  // p,
  // ul,
  // ol {
  //   a {
  //     font-weight: 600;
  //   }
  // }

  @include zen-respond-to(xxxs) {
    @include type-layout('m', 1);
  }
  @include zen-respond-to(xl) {
    @include type-layout('ml', 1.25);
  }
}

