// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.header,
%header {
  @extend %clearfix;
  @include transition-default();

  // overflow: hidden;

  @include zen-respond-to(s) {
    background: none;
  }

  // Alert - Top
  &__alert-top {
    position: relative;
    background: color('darkest-blue');
    z-index: 600;

    .region {
      position: relative;
      max-width: $body-width;
      width: 100%;
      margin: 0 auto;
      padding: 10px 15px;
    }

    @media print {
      display: none;
    }
  }

  // Wrapping link for logo.
  &__logo {
    float: left;
    margin: 0 10px 0 0;
    padding: 0;

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    @include transition-default();
    position: relative;

    top: 30px; //28px;
    left: 0;

    float: none;

    @include zen-respond-to(xxxs) {
      top: -105px; /* -105px; -97px; -28px 52px */
      /* -105px without banner, -30px with mini-banner */
    }
    @include zen-respond-to(xs) {
      top: 26px;
    }
    @include zen-respond-to(s) {
      top: 30px; //28px;
    }

    @media print {
      top: -30px;
    }
  }

  &__alert-top + &__name-and-slogan {
    @include zen-respond-to(xxxs) {
      top: -30px; /* -105px; -97px; -28px 52px */
      /* -105px without banner, -30px with mini-banner */
    }
    @include zen-respond-to(xs) {
      top: 26px;
    }
    @include zen-respond-to(s) {
      top: 28px;
    }

    @media print {
      top: -30px;
    }
  }

  // Container for site name / logo
  &__site-name-container {
    position: relative;
    max-width: $body-true-width;
    width: 100%;
    margin: 0 auto;

    overflow: visible;
  }

  // The name of the website.
  &__site-name {
    position: absolute;
    top: 0;
    left: 0; //15px; // 75px; 28px;
    margin: 0;
    padding: 0;
    z-index: 500;
    background: none; // color(logo-bg);
    //background: color(logo-bg) url('../images/bg-logo.jpg') right top repeat;
    //background-size: 100% 100%;

    @include zen-respond-to(xxxs) {
      // z-index: 0;
      //left: 0;
      // background: color('white');
      background: none;
    }
    @include zen-respond-to(xs) {
      top: 25px;
      // z-index: 0;
      //left: 0;
      // background: color('white');
      background: none;
    }
    // @include zen-respond-to(l) {
    //   z-index: 500;
    //   left: 0;
    //   background: color(logo-bg) url('../images/bg-logo.jpg') right top repeat;
    //   background-size: 100% 100%;
    // }
    @include zen-respond-to(m) {
      z-index: 500;
      top: 0;
      //left: 15px; // 75px; 28px;
      background: none; // color(logo-bg);
      //background: color(logo-bg) url('../images/bg-logo.jpg') right top repeat;
      // background-size: 100% 100%;
    }

    @media print {
      background: color('white');
    }
  }

  &__60-link {
    display: block;
    float: left;
    width: 140px;
    height: 98px;
    //border: 1px solid #f00;

    @include zen-respond-to(xxxs) {
      width: 120px;
    }
    @include zen-respond-to(m) {
      width: 140px;
    }

    // Hide text site-name
    span {
      display: none;
    }

    &:focus,
    &:hover {
      opacity: .5;
    }
  }

  // The link around the name of the website.
  &__site-link {
    color: color('black');
    text-decoration: none;
    float: left;
    display: block;
    width: 330px; //350px; // 250px; 225px;
    height: 98px; // 150px; // 141px; 124px; /*96px;*/
    //background: url('../images/logo.png') 25px 47px no-repeat;
    //border: 1px solid #0ff;

    @include zen-respond-to(xxxs) {
      //border: 1px solid #f00;
      width: 215px; // 275px;
      height: 100px;
    }
    @include zen-respond-to(xs) {
      //border: 1px solid #f0f;
      width: 215px;
      height: 95px;
    }
    // @include zen-respond-to(m) {
    //   border: 1px solid #00f;
    //   width: 275px;
    //   height: 65px; // 105px; 141px;
    // }
    // @include zen-respond-to(l) {
    //   border: 1px solid #0f0;
    //   width: 290px; // 250px; 225px;
    //   height: 65px; // 105px; // 124px;
    // }
    // @include zen-respond-to(xxl) {
    //   border: 1px solid #0ff;
    //   width: 330px; //350px; // 250px; 225px;
    //   height: 90px; //118px; // 141px; 124px;
    // }
    @include zen-respond-to(m) {
      //border: 1px solid #ff0;
      width: 330px; //350px; // 250px; 225px;
      height: 90px; //138px; // 141px; 124px;
    }

    @media print {
      width: 300px; // 152px;
      height: 97px; // 97px;
    }

    // Hide text site-name
    span {
      display: none;
    }

    &:focus,
    &:hover {
      opacity: .5;
    }

    /*@include zen-respond-to(xxxs) {
      margin-top: -20px;
    }
    @include zen-respond-to(s) {
      margin-top: 0;
    }*/
  }

  &__60-image {
    position: relative;
    border-right: 1px solid #bababa;
    top: 15px;
    left: 10px;
    max-width: 121px;
    max-height: 60px;
    padding-right: 25px;

    @include zen-respond-to(xxxs) {
      top: 40px;
      max-width: 100px;
      max-height: 50px;
    }
    @include zen-respond-to(xs) {
      top: 15px;
      max-width: 100px;
      max-height: 50px;
    }
    @include zen-respond-to(m) {
      top: 15px;
      max-width: 121px;
      max-height: 60px;
    }

    @media print {
      top: 40px; // 40px;
    }
  }

  // Logo image.
  &__logo-image {
    //border: 1px solid #0f0;
    position: relative;
    top: 15px; //61px; // 55px 47px;
    left: 10px;

    max-width: 309px; // 200px; 180px;
    max-height: 60px; // 64px; 58px;

    vertical-align: bottom;

    @include zen-respond-to(xxxs) {
      //border: 1px solid #f00;
      top: 45px;
      left: 10px;

      max-width: 200px; // 250px; // 135px;
      max-height: 39px; // 49px; // 42px;
    }
    @include zen-respond-to(xs) {
      //border: 1px solid #f0f;
      top: 20px; //59px; // 61px; 55px 47px;
      left: 10px; //25px;

      max-width: 200px; // 200px; 180px;
      max-height: 39px; // 64px; 58px;
    }
    // @include zen-respond-to(m) {
    //   border: 1px solid #00f;
    //   top: 0; // 30px; // 73px;
    //   left: 15px;

    //   max-width: 200px; // 240px;
    //   max-height: 39px; // 47px;
    // }
    // @include zen-respond-to(l) {
    //   border: 1px solid #0f0;
    //   top: 0; // 38px;
    //   left: 15px;

    //   max-width: 200px; // 267px;
    //   max-height: 39px; // 52px;
    // }
    // @include zen-respond-to(xxl) {
    //   border: 1px solid #0ff;
    //   top: 15px; //31px; // 61px; 55px 47px;
    //   left: 10px; //25px;

    //   max-width: 300px; // padding: 7px 0 0;200px; 180px;
    //   max-height: 59px; // 64px; 58px;
    // }
    @include zen-respond-to(m) {
      //border: 1px solid #ff0;
      top: 15px; //59px; // 61px; 55px 47px;
      left: 10px; //25px;

      max-width: 309px; // 200px; 180px;
      max-height: 60px; // 64px; 58px;
    }

    @media print {
      top: 45px; // 40px;
      left: 10px;

      width: 200px;
      height: auto;
      max-width: 200px; // 135px;
      max-height: 63px; // 42px;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  //
  // &__navigation {
  //   min-height: 128px;
  // }

  // Top quick link menu
  .block-menu {
    // @include type-layout(ms, 1);
    // @extend %navbar;

    overflow: auto;
    margin: 0 auto;
    max-width: $body-width;

    font-family: $open-sans; // $arial;
    // font-size: 92.3%;
    text-align: right;

    @include zen-respond-to(xxxs) {
      padding: .75em;
    }
    @include zen-respond-to(xs) {
      padding: 0;
    }

    @media print {
      display: none;
    }

    ul {
      margin: 0 auto;
      padding: 0 7px 0 0; // 2px  5px   20px
      overflow: visible;

      float: right;

      li,
      li.is-leaf {
        @include no-padding-margins;
        @include type-layout(ms, 1);

        float: left;

        list-style-type: none;
        list-style-image: none;

        @include zen-respond-to(xxxs) {
          @include type-layout(s, 1);
          text-align: left;
          width: 45%;
        }

        @include zen-respond-to(xs) {
          @include type-layout(ms, 1);
          width: auto;
          text-align: right;
        }

        a {
          display: block;
          margin: 0;
          padding: .4em .8em;

          color: color('white');

          text-decoration: none;

          &:active,
          &:focus,
          &:hover {
            /*opacity: 0.5;*/
            color: color(light-blue);
          }
        }
      }

      li.last {
        // @include transition-default();
        background: color('blue');

        // &:active,
        // &:focus,
        // &:hover {
        //   opacity: .7;
        // }

        // a,
        // a:active,
        // a:focus,
        // a:hover {
        //   color: color('black');
        // }
      }
    }
  }

  // Search form region
  &__search {
    clear: both;
    overflow: auto;
    padding: 17px 0;
  }

  .block-search {
    @include no-padding-margins;
    margin: 0 auto;
    padding: 0 15px 0 0; // 0 8px 0 0; //22px
    width: 100%;
    max-width: $body-width;
    overflow: auto;

    @include zen-respond-to(xxxs) {
      padding: 0 1em; //.5em 1.2em;
      background: color('light-blue');
    }
    @include zen-respond-to(xs) {
      //border: 1px solid #00f;
      padding: 50px 8px 0 0; // 10px 8px 10px 0;
      background: none;
    }
    @include zen-respond-to(s) {
      //border: 1px solid #0ff;
      padding: 0 15px 0 0; // 0 8px 0 0;
      background: none;
    }

    @media print {
      display: none;
    }
  }

  // Search submit image
  &__search-image {
    @include no-padding-margins;
    position: absolute;
    top: 20px; // 18px;
    right: 10px;

    width: 15px;
    opacity: .3;

    &:focus,
    &:hover {
      opacity: .5;
    }
  }

  // The main menu
  // Container
  &__main-menu {
    position: relative;

    max-width: $body-true-width;
    width: 100%;
    height: auto;

    margin: 0 auto;
    padding: 0; // 7px 0 0;

    // background: url('../images/bg-navigation-shadow.png') top right no-repeat;

    //border: 1px solid #00f;

    @include zen-respond-to(xxxs) {
      //background: #f00;
      background: none;
    }
    @include zen-respond-to(xs) {
      //background: #f0f;
      margin-top: -4px;
      // background: url('../images/bg-navigation-shadow.png') top right no-repeat;
    }
    @include zen-respond-to(s) {
      margin-top: 0;
      // background: url('../images/bg-navigation-shadow.png') top right no-repeat;
    }

    @media print {
      display: none;
    }

    // Actual main menu
    .main-menu,
    .region-main-menu {
      position: relative;

      margin: 0 auto;
      padding: 0; // 0 0 0 375px; // 390px; // 370px 276px; // 276px; 296px;

      overflow: visible; // auto;

      /*max-width: $body_width;  1036 740px;*/
      // background: url('../images/bg-navigation.png') top center repeat-y;
      border-top: 1px solid color('border');

      @include zen-respond-to(xxxs) {
        //position: absolute;
        //top: 0;
        //right: 0;
        width: 100%;
        height: auto;
        padding-left: 0;
        padding-top: 0;
      }
      /*@include zen-respond-to(xxs) {
        position: absolute;
        top: 0;
        right: 0;
        width: 200px;
        height: 200px;
        padding-left: 0;
        border: 1px solid #f00;
      }*/
      @include zen-respond-to(xs) {
        padding-top: 0;
        position: relative;
        padding-left: 0;

        width: auto;
        height: auto;
      }
      // @include zen-respond-to(s) {
      //   padding-left: 280px; // 254px;
      // }
      // @include zen-respond-to(m) {
      //   padding-left: 305px;
      // }
      // @include zen-respond-to(l) {
      //   padding-left: 305px;
      // }
      // @include zen-respond-to(xl) {
      //   padding-left: 320px;
      // }
      @include zen-respond-to(xxl) {
        position: relative;
        padding-left: 0; // 375px; // 390px; // 370px 276px;

        width: auto;
        height: auto;
      }

      ul.navbar {
        margin: 0 auto;
        overflow: auto;

        display: none;

        // background: color('off-white');

        li {
          @include type-layout(ml, .8);

          $main-menu-li-width-xs: 33.3%;
          $main-menu-li-width-xl: 33.3%;//16.66%;

          text-transform: uppercase;
          padding: 0;
          /*width: 150px;*/
          text-align: center;

          font-family: $georgia;
          letter-spacing: 1px;
          // font-size: 13px;
          // line-height: 120%;

          width: $main-menu-li-width-xl;
          // background: color('light-blue');

          @include zen-respond-to(xxxs) {
            @include type-layout(ms, 1);
            border-bottom: 1px solid color('border');
          }
          @include zen-respond-to(xs) {
            @include type-layout(m, .8);
            font-size: 13px;
            border: 0;
          }
          @include zen-respond-to(l) {
            font-size: 14px; // 13px;
          }

          // Programs & Degrees
          &.menu-157 {
            @include zen-respond-to(xxxs) {
              width: 100%;
            }
            @include zen-respond-to(xs) {
              width: $main-menu-li-width-xs;
              max-width: $main-menu-li-width-xs;

              a {
                padding-left: 0;
                padding-right: 0;
              }
            }
            @include zen-respond-to(xl) {
              width: $main-menu-li-width-xl + 3%; // 132px;
            }
          }

          // Future Students
          &.menu-2023 {
            @include zen-respond-to(xxxs) {
              width: 100%;
            }
            @include zen-respond-to(xs) {
              width: $main-menu-li-width-xs;
              max-width: $main-menu-li-width-xs;
            }
            @include zen-respond-to(xl) {
              width: $main-menu-li-width-xl + 1%; // 140px; // 125px;
            }
          }

          // Current Students
          &.menu-159 {
            @include zen-respond-to(xxxs) {
              width: 100%;
            }
            @include zen-respond-to(xs) {
              width: $main-menu-li-width-xs;
              max-width: $main-menu-li-width-xs;
            }
            @include zen-respond-to(xl) {
              width: $main-menu-li-width-xl + 1%; // 140px; // 125px;
            }
          }

          // Community
          &.menu-160 {
            @include zen-respond-to(xxxs) {
              width: 100%;
            }
            @include zen-respond-to(xs) {
              width: $main-menu-li-width-xs;
              max-width: $main-menu-li-width-xs;
            }
            @include zen-respond-to(xl) {
              width: $main-menu-li-width-xl + 1%; // 11em;

              a {
                padding-top: 19px;
                padding-bottom: 18px;
              }
            }
          }

          // Events & News
          &.menu-18767 {
            @include zen-respond-to(xxxs) {
              width: 100%;
            }
            @include zen-respond-to(xs) {
              width: $main-menu-li-width-xs;
              max-width: $main-menu-li-width-xs;
            }
            @include zen-respond-to(xl) {
              width: $main-menu-li-width-xl - 1%; // 8em;
            }
          }

          // About MCC
          &.menu-815 {
            @include zen-respond-to(xxxs) {
              width: 100%;
            }
            @include zen-respond-to(xs) {
              width: $main-menu-li-width-xs;
              max-width: $main-menu-li-width-xs;
            }
            @include zen-respond-to(xl) {
              width: $main-menu-li-width-xl - 5%; // 99px;
            }
          }

          a {
            display: block;
            padding: 9px 30px 7px; // 9px 20px 7px;

            text-decoration: none;

            color: color(text);

            //border: 1px solid #f00;

            //background: #00f;

            &:active,
            &:focus,
            &:hover {
              /*opacity: 0.5;*/
              color: color('blue');
              background: color('menu-bg-active');
            }

            @include zen-respond-to(xxxs) {
              padding: 1em;
            }
            @include zen-respond-to(xs) {
              padding: 10px 0;
            }
            @include zen-respond-to(s) {
              padding: 12px 3px; // 12px 5px 0;
              min-height: 45px;
              //background: #0ff;
            }
            @include zen-respond-to(m) {
              padding: 10px 15px;
              min-height: 42px;

              //background: #f0f;
            }
            @include zen-respond-to(l) {
              padding: 10px 0;
              min-height: 30px;

              //background: #666;
            }
            @include zen-respond-to(xl) {
              padding: 11px 5px; // 11px 0px 9px 20px 7px;
              min-height: auto;

              //background: #0ff;
            }
          }

          ul {
            display: none;
          }
        }
      }

      .menu-name-main-menu > ul.menu {
        //@extend %navbar;

        overflow: visible;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        //align-items: center;

        margin: 0;
        padding: 0;

        //border: 1px solid #0f0;

        & > li {
          //@include type-layout(m, .8);
          @include type-layout(ml, 1);
          font-weight: 500;

          // position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          //align-items: center;

          //border: 1px solid #00f;

          text-transform: uppercase;
          padding: 0;
          /*width: 150px;*/
          text-align: center;

          font-family: $open-sans; //$georgia;
          // letter-spacing: 1px;

          a {
            color: color('text');

            display: flex;
            height: 100%;
            justify-content: center;
            // flex-direction: column;
            align-items: center;

            padding: .75em 1em;
            text-decoration: none;
            text-align: center;

            // border: 1px solid #f00;

            &:active,
            &:focus,
            &:hover {
              /*opacity: 0.5;*/
              color: color('blue');
              background: color('menu-bg-active');
            }

            @include zen-respond-to(xxxs) {
              border-bottom: 1px solid color('border');
            }
            @include zen-respond-to(s) {
              border-bottom: 0;
            }
          }

          .expand {
            @include zen-respond-to(xxxs) {
              position: absolute;
              z-index: 99999;
              display: block;
              padding: .75em 1em;
              top: 0;
              right: 0;
              // border: 1px solid #00f;
            }
            @include zen-respond-to(xs) {
              display: none;
            }
          }
        }

        li ul {
          // display: flex;
          // flex-direction: column;
          // flex-wrap: wrap;
          // align-items: flex-start;

          // display: grid;
          // grid-template-columns: 33.3%;
          // grid-template-rows: 50px;
          // grid-auto-flow: row;

          // grid-template-columns: repeat(auto-fill, minmax(31.3%, 1fr));
          // grid-gap: 1rem;
          // grid-template-columns: repeat(3,minmax(auto,1fr));

          columns: 3 auto;
          // column-count: 3;
          // column-fill: auto;
          column-gap: 0;

          display: block;
          // display: none;
          // opacity: 0;
          // visibility: hidden;

          position: absolute;
          z-index: 9999;
          //top: 3.9em; //-999999em; //
          top: -999999em;
          right: 0;
          max-width: ($body-true-width - 390px);

          margin: 0;
          padding: 0;

          // border: 1px solid #00f;
          background: color('off-white');

          -webkit-filter: drop-shadow( 3px 3px 3px rgba(0, 0, 0, .5) );
          filter: drop-shadow( 3px 3px 3px rgba(0, 0, 0, .5) );

          li {
            display: inline-block;
            width: 100%;
            // flex-basis: 33.3%;

            font-size: 100%;
            text-transform: none;
            text-align: left;

            // border: 1px solid #f0f;

            @include zen-respond-to(xxxs) {
              font-size: 80%;
            }
            @include zen-respond-to(s) {
              font-size: 100%;
            }

            a {
              display: block;
              padding: .8em 1em;

              text-align: left;
              // justify-content: left;

              // border: 1px solid #0ff;
              border-bottom: 0;
              border-right: 1px solid color('grey-light');

              &:focus,
              &:hover {
                background: color('light-blue');
              }
            }
          }
        }

        li:focus,
        li:hover,
        li.focused {
          background: color('menu-bg-active');

          ul {
            //display: block;
            opacity: 1;
            top: 2.7em; //3.8em;

            @include zen-respond-to(xxxs) {
              // display: none;
              // opacity: 0;
              position: absolute;
              top: -999999em;
              // display: block;
              // position: initial;
            }
            @include zen-respond-to(s) {
              position: absolute;
              display: block;
              opacity: 1;
              top: 3.3em;
              // border-bottom: 1px solid #0f0;
            }
            @include zen-respond-to(m) {
              top: 3.5em;
              // border-bottom: 1px solid #00f;
            }
            @include zen-respond-to(xxl) {
              top: 2.7em; //3.8em;
              // border-bottom: 1px solid #f00;
            }
          }
        }

        li.focused {
          background: color('white');

          ul {
            @include zen-respond-to(xxxs) {
              //display: none;
              //opacity: 0;
              //top: -999999em;
              //display: block;
              position: initial;
              // top: auto;
            }
            @include zen-respond-to(s) {
              position: absolute;
            }
          }

          .expand {
            transform: rotate(90deg);
            right: 1px;
          }
        }

        @include zen-respond-to(xxxs) {
          li {
            position: relative;
            flex-basis: 100%;

            ul {
              max-width: $body-true-width;
            }
          }
        }
        @include zen-respond-to(xs) {
          li {
            position: static;
            flex-basis: 33.3%; // 48.5%;

            font-size: 1.1em;
            line-height: 1.1em;

            ul {
              max-width: $body-true-width;
            }
          }
        }
        @include zen-respond-to(s) {
          // border: 1px solid #0ff;

          li {
            font-size: 1em;
            line-height: 1em;

            flex-basis: 16.66%; // 31.3%;

            ul {
              max-width: $body-true-width;
            }
          }
        }
        @include zen-respond-to(l) {
          // border: 1px solid #f0f;

          li {
            font-size: 1.1em;
            line-height: 1.1em;

            flex-basis: 16.66%;

            a {
              padding: .75em .5em;
            }

            ul {
              max-width: $body-true-width;
            }
          }
        }
        @include zen-respond-to(xl) {
          // border: 1px solid #0f0;

          li {
            font-size: 1.2em;
            line-height: 1.2em;

            flex-basis: 16.66%;

            a {
              padding: .75em 1em;
            }

            ul {
              max-width: $body-true-width;
            }
          }
        }
        @include zen-respond-to(xxl) {
          // border: 1px solid #f00;

          li {
            font-size: 1.2em;
            line-height: 1.2em;

            flex-basis: 16.66%;

            a {
              padding: .75em .5em;
            }

            ul {
              max-width: $body-true-width; //($body-true-width - 410px);
            }
          }
        }
        @include zen-respond-to(xxxl) {
          // border: 1px solid #00f;

          li {
            font-size: 1.2em;
            line-height: 1.2em;

            flex-basis: 16.66%;

            a {
              padding: .75em 1em;
            }

            ul {
              max-width: $body-true-width; //($body-true-width - 390px);
            }
          }
        }
      }
    }
  }

  &__image-container,
  &__alert {
    color: color('white');
    background: color('grey-dark');

    @include zen-respond-to(xxxs) {
      margin-top: 70px;
    }
    @include zen-respond-to(xs) {
      margin-top: 0;
    }

    @media print {
      margin-top: 70px;
    }

    &.after-alert {
      margin-top: 0;
    }
  }

  // &__image {
  //   //border: 1px solid #0f0;
  // }

  &__image-background,
  %header__image-background {
    position: absolute;
    width: 100%;
    height: $header-image-height;
    overflow: hidden;
    z-index: 50;

    background: color('grey-dark');

    img {
      width: 100%;
    }

    @include zen-respond-to(xxxs) {
      display: none;
      height: auto;
    }
    @include zen-respond-to(xl) {
      display: none; // block;
      height: $header-image-height;
    }
  }

  &__image-photo,
  %header__image-photo {
    max-width: 100%; // $body-width;
    height: auto; // $header-image-height;
    margin: 0 auto;
    overflow: hidden;
    z-index: 60;

    //border: 1px solid #f0f;

    @include zen-respond-to(xxxs) {
      height: auto;
    }
    @include zen-respond-to(xl) {
      height: auto; // $header-image-height;
    }

    .section {
      position: relative;
      top: 0;
      left: 0;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      width: auto;
      z-index: 70;
      overflow: hidden;

      img {
        position: relative;
        float: left;
        top: 0;
        // left: -50%;
        // margin-left: 50%;
        padding-left: 0; // $body-gutters;

        //margin-left: $body-gutters;
        // Because of the right overhang of messaging, add back an additional 10px
        width: 100%; // $body-true-width + $body-gutters;
        max-width: 100%; // $body-true-width + $body-gutters;

        // @include zen-respond-to(xxxs) {
        //   //left: -90%;
        //   left: -75%;
        //   margin-left: 75%;
        //   padding-left: 0;

        //   width: 150%;
        //   max-width: 150%;
        // }
        // @include zen-respond-to(xs) {
        //   left: -50%;
        //   margin-left: 50%;

        //   width: 100%; // $body-true-width + $body-gutters;
        //   max-width: 100%;
        // }
        // @include zen-respond-to(xxl) {
        //   left: -50%;
        //   margin-left: 50%;

        //   width: 100%; // $body-true-width + $body-gutters;
        //   max-width: 100%;
        // }
      }

      .messaging,
      %header__messaging {
        @include transition-default();

        position: absolute;
        top: 6vw; // 60px;
        // left: 11vw; // null
        right: 5vw; // auto; // 0;
        margin: 0;
        padding: 0; // 0 0 0 100px; // 500px
        z-index: 80;
        /*width: 410px;*/

        color: color(light-blue);
        font-weight: normal;
        line-height: 100%;
        font-family: $open-sans; // $georgia;

        @include zen-respond-to('xxxs') {
          top: 3.5vw;
          right: 0;
        }
        @include zen-respond-to('xs') {
          top: 5vw;
          right: 5vw;
        }
        @include zen-respond-to('xl') {
          top: 6vw;
          right: 5vw;
        }

        &.wider {
          padding-left: 0;
        }
      }

      .messaging-block {
        @include type-layout(ml, 1.5);
        width: 30%;
        margin: 0;
        padding: 2em;

        color: color('grey-dark');
        background: rgba(255, 255, 255, .9);

        // background: url('../images/bg-future-students-benefits.png') top left no-repeat;

        @include zen-respond-to(xxxs) {
          @include type-layout(ms, 1);
          top: 36%;
          width: 90vw;
          padding: 2vw; // 2vw 2vw 2vw 2em;
        }
        @include zen-respond-to(xs) {
          @include type-layout(m, 1);
          top: 32%;
          width: 70vw;
        }
        @include zen-respond-to(m) {
          @include type-layout(ml, 1.5);
          width: 60vw;
        }
        @include zen-respond-to(xxxl) {
          @include type-layout(ml, 1.5);
          width: 30%;
          padding: 2em;
        }

        p a {
          // display: block;
          // text-decoration: none;
          color: color('link');
          // border: 1px solid #0f0;

          &:hover {
            opacity: .6;
          }
        }

        h2 {
          margin: 0 0 .5em;
          padding: 0;
        }

        h3 {
          margin-bottom: 1em;
        }

        h4 {
          @include type-layout(xl, 1.2);
          clear: both;
          margin-top: 0;
          margin-bottom: 1em;
          font-weight: 600;
          // color: rgba(198, 66, 0, 1);

          @include zen-respond-to(xxxs) {
            font-size: 3vw; //6vw
            line-height: 120%;
            margin-bottom: 0;
          }
          @include zen-respond-to(xs) {
            font-size: 3vw;
          }
          @include zen-respond-to(m) {
            font-size: 2vw;
          }
          @include zen-respond-to(xl) {
            @include type-layout(xl, 1.2);
            margin-bottom: 1em;
          }
        }

        p {
          clear: both;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .tall &__image-photo .messaging {
    top: 15vw;
  }

  .thin &__image-photo .messaging {
    top: 8vw;
  }

  &__image-video {
    span {
      display: none;
    }

    span.video-disclaimer {
      @include type-layout(s, 1);
      display: block;
      position: absolute;
      bottom: .5em;
      right: .5em;
      opacity: .3;
    }
  }

  // &__image-messaging-center {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;

  //   .section {
  //     width: 100%;
  //   }

  //   .messaging {
  //     top: auto;
  //     left: auto;
  //   }
  // }

  &__section-header {
    @include transition-default();
    float: right;
    margin: 0;
    // background: color('blue');
    // background: rgba(0, 84, 166, .8);
    background: rgba(255, 255, 255, .9);
    color: color('white');
    font-family: $open-sans; // $arial;
    font-weight: 100;
    font-size: 2.538em;
    line-height: 1.3em;
    //-webkit-print-color-adjust: exact;

    @include zen-respond-to('xxxs') {
      font-size: 4vw; // 5.5vw; //6vw
    }
    @include zen-respond-to('xs') {
      font-size: 3.8vw;
    }
    @include zen-respond-to('xl') {
      font-size: 3vw; // 2.538em;
    }

    /* Section headers longer than 25 characters */
    .wider & {
      @include zen-respond-to('xxxs') {
        font-size: 3.2vw;
      }
      @include zen-respond-to('xs') {
        font-size: 3vw;
      }
      @include zen-respond-to('xl') {
        font-size: 2.5vw;
      }
    }

    /* Section headers longer than 40 characters */
    .extra-wide & {
      @include zen-respond-to('xxxs') {
        font-size: 3vw;
      }
      @include zen-respond-to('xs') {
        font-size: 2.5vw;
      }
      @include zen-respond-to('xl') {
        font-size: 2vw;
      }
    }

    @media print {
      color: color('black') !important;
      text-shadow: 0 0 1px color('white'), 0 0 2px color('white'), 0 0 5px color('white') !important;
    }

    a,
    .header__section-header-padding {
      display: block;
      // padding: .3em 2em .3em .5em;
      padding: .2em .4em;

      // color: color('white');
      color: color('blue') !important;
      text-decoration: none;

      &:focus,
      &:active,
      &:hover {
        opacity: .5;
      }
    }

    span:focus,
    span:active,
    span:hover {
      opacity: 1;
    }
  }

  &-image.hidden &__image-background,
  &-image.hidden .header-photo {
    display: none;
  }


  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    position: relative;
    z-index: 600;

    // Clear the logo.
    clear: both;

    margin: 0 auto;

    overflow: auto;
    background: color('darkest-blue');

    /* Alert Box - Mini Banner */
    .block-block {
      width: 100%;
      max-width: $body-width;
      margin: 0 auto;
      padding: 0 15px;
    }
  }

  // //
  // // Section specific colors and widths/heights
  // //
  // .section-50th-anniversary &__section-header {
  //   background: rgba(4, 81, 137, .8);
  // }

  // .section-about &__section-header,
  // .section-about-mcc &__section-header {
  //   background: rgba(48, 117, 191, .8);
  // }

  // .section-academic-advisement &__section-header {
  //   background: rgba(198, 66, 0, .8);
  // }

  // .section-admissions-records &__section-header {
  //   background: rgba(158, 88, 14, .8);
  // }

  // .section-alumni &__section-header {
  //   background: rgba(117, 0, 6, .8);
  // }

  // .section-american-indian-center &__section-header {
  //   background: rgba(35, 61, 140, .8);
  // }

  // .section-bookstore &__section-header {
  //   background: rgba(36, 58, 122, .8);
  // }

  // .section-calendars &__section-header {
  //   background: rgba(204, 29, 1, .8);
  // }

  // .section-career &__section-header {
  //   background: rgba(224, 23, 4, .8);
  // }

  // .section-cashier-services &__section-header {
  //   background: rgba(255, 47, 63, .8);
  // }

  // .section-catalog &__section-header {
  //   background: rgba(41, 69, 216, .8);
  // }

  // .section-checklists &__section-header {
  //   background: rgba(17, 119, 78, .8);
  // }

  // .section-community &__section-header {
  //   background: rgba(169, 101, 37, .8); /*rgba(228, 160, 13, .8);*/
  // }

  // .section-community-civic-engagement &__image-photo .messaging,
  // .section-service-learning &__image-photo .messaging {
  //   // padding-left: 400px;

  //   .header__section-header {
  //     background: rgba(28, 116, 174, .8);
  //   }
  // }

  // .section-contacts &__section-header {
  //   background: rgba(141, 4, 65, .8);
  // }

  // .section-current-students &__section-header,
  // .section-students &__section-header {
  //   background: rgba(111, 18, 0, .8);
  // }

  // .section-departments &__section-header,
  // .section-academic-departments &__section-header {
  //   background: rgba(109, 2, 12, .8);
  // }

  // .section-departments-administration-justice &__section-header {
  //   background: rgba(109, 2, 12, .8);
  // }

  // .section-departments-applied-sciences-technology &__image-photo .messaging,
  // .section-departments-applied-sciences-and-technology &__image-photo .messaging {
  //   // padding-left: 400px;

  //   .header__section-header {
  //     background: rgba(27, 41, 78, .8);
  //   }
  // }

  // .section-departments-art &__section-header {
  //   background: rgba(165, 15, 17, .8);
  // }

  // .section-departments-business-information-systems &__image-photo .messaging {
  //   // padding-left: 400px;

  //   .header__section-header {
  //     background: rgba(165, 15, 17, .8);
  //   }
  // }

  // .section-departments-communication-theatre-film-arts &__image-photo .messaging {
  //   // padding-left: 400px;

  //   .header__section-header {
  //     background: rgba(28, 26, 123, .8);
  //   }
  // }

  // .section-departments-counseling &__section-header {
  //   background: rgba(72, 54, 92, .8);
  // }

  // .section-departments-cultural-science &__section-header {
  //   background: rgba(221, 24, 44, .8);
  // }

  // .departments-education-studies &__section-header {
  //   background: rgba(9, 107, 34, .8);
  // }

  // .section-departments-english &__section-header {
  //   background: rgba(68, 69, 113, .8);
  // }

  // .section-departments-exercise-science &__section-header {
  //   background: rgba(8, 31, 111, .8);
  // }

  // .section-departments-fire-science-emt &__section-header {
  //   background: rgba(153, 0, 3, .8);
  // }

  // .section-departments-life-science &__section-header {
  //   background: rgba(215, 61, 49, .8);
  // }

  // .section-departments-mathematics-computer-science &__image-photo .messaging {
  //   // padding-left: 400px;

  //   .header__section-header {
  //     background: rgba(87, 124, 86, .8);
  //   }
  // }

  // .section-departments-music &__section-header {
  //   background: rgba(205, 10, 39, .8);
  // }

  // .section-departments-nursing &__section-header {
  //   background: rgba(41, 93, 150, .8);
  // }

  // .section-departments-philosophy-religious-studies &__section-header {
  //   background: rgba(217, 41, 64, .8);
  // }

  // .section-departments-physical-science &__section-header {
  //   background: rgba(225, 44, 13, .8);
  // }

  // .section-departments-psychological-science &__section-header {
  //   background: rgba(187, 87, 0, .8);
  // }

  // .section-departments-reading &__section-header {
  //   background: rgba(200, 75, 89, .8);
  // }

  // .section-departments-social-science &__section-header {
  //   background: rgba(35, 114, 198, .8);
  // }

  // .section-departments-world-languages &__section-header {
  //   background: rgba(85, 114, 68, .8);
  // }

  // .section-disability-services &__section-header {
  //   background: rgba(4, 89, 99, .8);
  // }

  // .section-early-college-programs &__section-header {
  //   background: rgba(138, 46, 46, .8);
  // }

  // .section-employees &__section-header {
  //   background: rgba(44, 77, 164, .8);
  // }

  // .section-error &__section-header {
  //   background: rgba(9, 60, 131, .8);
  // }

  // .section-events &__section-header {
  //   background: rgba(198, 66, 0, .8);
  // }

  // .section-espanol &__section-header {
  //   background: rgba(219, 4, 45, .8);
  // }

  // .section-financial-aid &__section-header {
  //   background: rgba(198, 66, 0, .8);
  // }

  // .section-future-students &__section-header {
  //   background: rgba(198, 66, 0, .8);
  // }

  // .section-graduation &__section-header {
  //   background: rgba(178, 3, 3, .8);
  // }

  // .section-giving &__section-header {
  //   background: rgba(198, 66, 0, .8);
  // }

  // .section-help &__section-header,
  // .section-helpcenter &__section-header {
  //   background: rgba(40, 143, 56, .8);
  // }

  // .section-high-school &__section-header,
  // .section-high-school-students &__section-header {
  //   background: rgba(217, 53, 27, .8);
  // }

  // .section-honors &__section-header {
  //   background: rgba(208, 12, 22, .8);
  // }

  // .section-human-resources &__section-header {
  //   background: rgba(78, 90, 160, .8);
  // }

  // .section-international-education &__section-header {
  //   background: rgba(36, 105, 149, .8);
  // }

  // .section-library &__section-header {
  //   background: rgba(241, 37, 42, .8);
  // }

  // .section-live &__section-header,
  // .section-itunes-u &__section-header {
  //   background: rgba(237, 59, 4, .8);
  // }

  // .section-locations &__section-header {
  //   background: rgba(224, 37, 0, .8);
  // }

  // .section-mcc-online &__section-header,
  // .section-online &__section-header,
  // .section-elearning &__section-header {
  //   background: rgba(31, 84, 36, .8);
  // }

  // .section-online-community &__section-header {
  //   background: rgba(47, 52, 129, .8);
  // }

  // .section-other &__section-header {
  //   background: rgba(17, 95, 172, .8);
  // }

  // .section-outreach &__image-photo .messaging {
  //   // padding-left: 400px;

  //   .header__section-header {
  //     background: rgba(198, 14, 27, .8);
  //   }
  // }

  // .section-news &__section-header {
  //   background: rgba(115, 13, 4, .8);
  // }

  // .section-programs &__section-header,
  // .section-programs-degrees &__section-header,
  // .programs &__section-header,
  // .programs-degrees &__section-header {
  //   background: rgba(72, 67, 136, .8);
  // }

  // .section-public-safety &__section-header {
  //   background: rgba(194, 6, 13, .8);
  // }

  // .section-recycling-sustainability &__section-header {
  //   background: rgba(14, 103, 69, .8);
  // }

  // .section-red-mountain &__section-header {
  //   background: rgba(61, 57, 172, .8);
  // }

  // .section-registration &__section-header {
  //   background: rgba(61, 57, 172, .8);
  // }

  // .section-schedule &__section-header,
  // .schedule &__section-header {
  //   background: rgba(101, 56, 97, .8);
  // }

  // .section-section-search &__section-header {
  //   background: rgba(65, 105, 161, .8);
  // }

  // .section-senior-discount &__image-photo .messaging {
  //   // padding-left: 400px;

  //   /*.header__section-header {
  //     background: rgba(198, 14, 27, .8);
  //   }*/
  // }

  // .section-student-life &__section-header,
  // .section-student-life-leadership &__section-header {
  //   background: rgba(49, 57, 188, .8);
  // }

  // .section-study-abroad &__section-header {
  //   background: rgba(3, 109, 123, .8);
  // }

  // .section-testing-services &__section-header {
  //   background: rgba(49, 54, 89, .8);
  // }

  // .section-tl3c &__section-header {
  //   background: rgba(42, 117, 120, .8);
  // }

  // .section-track-field-championships &__section-header {
  //   background: rgba(209, 22, 53, .8);
  // }

  // .section-transfer &__section-header {
  //   background: rgba(122, 20, 33, .8);
  // }

  // .section-venue-rentals &__section-header {
  //   background: rgba(111, 18, 0, .8);
  // }

  // .section-veteran-services &__section-header {
  //   background: rgba(169, 0, 37, .8);
  // }

  // .section-workforce-development &__section-header {
  //   background: rgba(0, 84, 166, .8);
  // }

  /* Image Gallery / Header Slideshow */
  &__image-gallery {
    position: relative;
    //border: 1px solid #00f;

    .image-gallery__overlay {
      //@extend %image-photo;
      position: absolute;
      width: 100%;
      //height: 100%;
      z-index: 100;

      //border: 1px solid #0f0;

      .messaging {
        position: relative;
        // max-width: $body-width;
        //height: $header-image-height-tall;
        right: 5vw;
        margin: 0 auto;
        overflow: visible;
        text-align: right;

        //border: 1px solid #f00;

        .header__section-header {
          position: absolute;
          top: 0;
          right: 0;
          width: 45%;
          margin-top: 4%;
          text-align: left;

          @include zen-respond-to(xxxs) {
            width: 90vw;
            margin-top: 2vw;
          }
          @include zen-respond-to(xs) {
            width: 70vw;
          }
          @include zen-respond-to(m) {
            width: 60vw;
          }
          @include zen-respond-to(xl) {
            width: 45%;
            margin-top: 4%;
          }

          span {
            padding-left: .3em;
            text-align: left;
          }
        }

        //border: 1px solid #f0f;

        @include zen-respond-to(xxxs) {
          right: 0;
        }
        @include zen-respond-to(xs) {
          right: 5vw;
        }
      }
    }

    .image-gallery__nav-overlay {
      position: absolute;
      bottom: 0;
      left: auto;
      right: 4.8vw;
      width: 100%;
      //height: 500px;
      margin: 0 auto;
      //border: 1px solid #f0f;
      z-index: 200;
      //width: 100%;

      .image-gallery__nav {
        //border: 1px solid #f00;
        margin: 0 auto;
        // max-width: $body-width;
        text-align: right;

        ul {
          @include type-layout(s, 1);
          float: right;
          font-family: $open-sans;
          line-height: 100%;
          // position: relative;
          // right: 0;
          // bottom: 0;
          // width: 40%;
          margin: 1em 3em 2em 0;
          padding: 0 0 0 3em;
          overflow: visible;
          text-align: left;

          // border: 1px solid #0f0;

          @include zen-respond-to(xxxs) {
            display: none;
          }
          @include zen-respond-to(s) {
            display: block;
          }

          li {
            float: left;

            list-style-type: none;
            list-style-image: none;

            a {
              @include transition-default();
              // @include box-shadow();

              display: block;
              width: 1.6em;
              height: 1.6em;
              margin-right: 1em;
              padding-top: .3em;

              text-align: center;
              text-decoration: none;
              color: color('black');
              background: rgba(255, 255, 255, .9);

              &:focus,
              &:hover {
                background: rgba(255, 255, 255, .7);
              }
            }

            &.active a {
              color: color('white');
              background: color('blue');
            }

            &:last-child a {
              margin-right: 0;
            }
          }
        }
      }
    }

    // .header__image-background,
    // .header__image-photo {
    //   height: $header-image-height-tall;

    //   @include zen-respond-to(xxxs) {
    //     height: auto;
    //   }
    //   @include zen-respond-to(xl) {
    //     height: $header-image-height-tall;
    //   }
    // }

    .header__image {
      //@include transition-default(500ms);
      -webkit-transition: opacity 500ms ease-in-out;
      -moz-transition: opacity 500ms ease-in-out;
      -o-transition: opacity 500ms ease-in-out;
      transition: opacity 500ms ease-in-out;

      position: absolute;
      top: 0;
      width: 100%;

      opacity: 0;

      //min-height: $header-image-height-tall;

      &.show {
        opacity: 1;
      }

      .messaging {
        top: 20%;
        width: 40%;
        margin: 0;
        padding: 0;

        @include zen-respond-to(xxxs) {
          top: 5%;
          width: 80vw;
          // padding: 2vw 2vw 2vw 2em;
        }
        @include zen-respond-to(xs) {
          top: 10%;
          width: 57vw;
        }
        @include zen-respond-to(s) {
          top: 10%;
          width: 55vw;
        }
        @include zen-respond-to(m) {
          top: 15%;
          width: 47vw;
        }
        @include zen-respond-to(xl) {
          top: 20%;
          width: 40%;
        }

        &.wider {
          @include zen-respond-to(xxxs) {
            width: 80vw; // 95vw;
          }
          @include zen-respond-to(xs) {
            width: 57vw;
          }
          @include zen-respond-to(s) {
            width: 55vw;
          }
          @include zen-respond-to(m) {
            width: 57vw;
          }
          @include zen-respond-to(xl) {
            width: 45%;
          }

          h2.header__section-header {
            width: 100%;
          }
        }

        a {
          display: block;
          text-decoration: none;
          color: color('grey-dark');
          //border: 1px solid #0f0;
        }

        h2.header__section-header {
          clear: left;
          width: 100%;
          padding: .3em .5em;
          margin-bottom: .2em;

          color: color('blue');
          background: color('white');
          border: 0;

          font-family: $open-sans; // $georgia;
          text-align: left;

          @include zen-respond-to(xxxs) {
            width: 80%;
            background: rgba(255, 255, 255, .9);
          }
          @include zen-respond-to(xs) {
            width: 100%;
            background: color('white');
          }
        }

        h3.header__section-header {
          span {
            float: left;
            padding-right: .5em;
          }

          .call-to-action-button-white {
            @include transition-default();

            clear: none;
            float: left;
            margin: .6em;
            padding: 0 2.5em 0 1em;
            color: color('text');

            // &:focus,
            // &:hover {
            //   opacity: .6;
            // }

            @include zen-respond-to(xxxs) {
              margin: .4em 0 0;
              transform: scale(.88);
              font-size: 4vw;
              background-size: auto 50%, cover;
            }
            @include zen-respond-to(xxs) {
              margin: .6em;
              transform: scale(1);
              font-size: 14px;
              background-size: auto auto, cover;
            }
          }
        }

        h3.header__section-sub-header {
          @include no-padding-margins();
          clear: left;
          float: left;
          display: inline-block;

          padding: .2em .5em;

          color: color('white');
          background: color('campaign-blue');

          text-transform: uppercase;
          font-family: $open-sans;
          font-size: 3vw; /*font-size: 171.4%;*/
          font-weight: 300;
          line-height: 100%;

          @include zen-respond-to(xxxs) {
            font-size: 4vw;
          }
          @include zen-respond-to(xs) {
            font-size: 3vw;
          }
          @include zen-respond-to(s) {
            font-size: 2vw;
          }
        }

        h4 {
          @include type-layout(xl, 1);
          margin-top: 0;
          margin-bottom: 1em;
          font-weight: 600;
          // color: rgba(198, 66, 0, 1);

          @include zen-respond-to(xxxs) {
            font-size: 3vw; //6vw
            line-height: 100%;
            margin-bottom: 0;
          }
          @include zen-respond-to(xs) {
            font-size: 3vw;
          }
          @include zen-respond-to(m) {
            font-size: 2vw;
          }
          @include zen-respond-to(xl) {
            @include type-layout(xl, 1);
            margin-bottom: 1em;
          }
        }

        h5 {
          @include no-padding-margins();
          // @include type-layout('xxl', 1);
          clear: left;
          // float: left;
          display: block;

          margin: 0 0 1em;

          color: color('white');
          font-family: $open-sans;
          font-size: 1.5vw; /*font-size: 171.4%;*/
          font-weight: 600;
          line-height: 200%;

          text-shadow: 0 0 20px rgba(0, 0, 0, 1);

          @include zen-respond-to(xxxs) {
            @include type-layout('ml', 1);
            margin: 0 0 .2em;
          }
          @include zen-respond-to(xs) {
            @include type-layout('xl', 1.5);
            margin: 0 0 .5em;
          }
          @include zen-respond-to(xl) {
            @include type-layout('xl', 2);
            margin: 0 0 1em;

            font-size: 24px;
            font-size: 1.84615rem;
            line-height: 40px;
            line-height: 3.07692rem;
          }

          &.add-background {
            background-color: color('blue');
            padding: 1em;
            border: 1px solid #00f;
          }
        }

        p {
          @include type-layout(l, 1.25);
          margin-bottom: 0;
          font-family: $open-sans;

          @include zen-respond-to(xxxs) {
            font-size: 2.5vw; //6vw
            line-height: 150%;
          }
          // @include zen-respond-to(xs) {
          //   //
          // }
          @include zen-respond-to(m) {
            font-size: 1.75vw; //6vw
          }
          @include zen-respond-to(xl) {
            @include type-layout(l, 1.25);
          }
        }
      }
    }

    #header-image-1 {
      position: relative;
      top: auto;
      width: auto;
    }
  }

  /* Homepage / Front - Header Image Gallery */
  // .front {
  //   #header-image-1 {
  //     .header__image-background { background-color: color('campaign-color-1'); }
  //     .messaging h2 { color: color('campaign-color-1'); }
  //     .messaging h3 { background-color: rgba(color('campaign-color-1'), .8); }
  //   }

  //   #header-image-2 {
  //     .header__image-background { background-color: color('campaign-color-2'); }
  //     .messaging h2 { color: color('campaign-color-2'); }
  //     .messaging h3 { background-color: rgba(color('campaign-color-2'), .8); }
  //   }

  //   #header-image-3 {
  //     .header__image-background { background-color: color('campaign-color-3'); }
  //     .messaging h2 { color: color('campaign-color-3'); }
  //     .messaging h3 { background-color: rgba(color('campaign-color-3'), .8); }
  //   }

  //   #header-image-4 {
  //     .header__image-background { background-color: color('campaign-color-4'); }
  //     .messaging h2 { color: color('campaign-color-4'); }
  //     .messaging h3 { background-color: rgba(color('campaign-color-4'), .8); }
  //   }

  //   #header-image-5 {
  //     .header__image-background { background-color: color('campaign-color-5'); }
  //     .messaging h2 { color: color('campaign-color-5'); }
  //     .messaging h3 { background-color: rgba(color('campaign-color-5'), .8); }
  //   }

  //   .image-gallery__nav {
  //     li.active:nth-child(5n+1) a { background-color: color('campaign-color-1'); }
  //     li.active:nth-child(5n+2) a { background-color: color('campaign-color-2'); }
  //     li.active:nth-child(5n+3) a { background-color: color('campaign-color-3'); }
  //     li.active:nth-child(5n+4) a { background-color: color('campaign-color-4'); }
  //     li.active:nth-child(5n+5) a { background-color: color('campaign-color-5'); }
  //   }
  // }

  /* Future Students - Header Image Gallery */
  .future-students {
    .header__image {
      .messaging {
        top: 32%;
        width: 45%;
        margin: 0;
        padding: 2em;

        color: color('grey-dark');
        background: rgba(255, 255, 255, .9);

        border-left: 10px solid color('blue');
        // background: url('../images/bg-future-students-benefits.png') top left no-repeat;

        @include zen-respond-to(xxxs) {
          top: 36%;
          width: 90vw;
          padding: 2vw; // 2vw 2vw 2vw 2em;
        }
        @include zen-respond-to(xs) {
          top: 32%;
          width: 70vw;
        }
        @include zen-respond-to(m) {
          width: 60vw;
        }
        @include zen-respond-to(xl) {
          width: 45%;
          padding: 2em;
        }

        a {
          display: block;
          text-decoration: none;
          // color: color('grey-dark');
          // border: 1px solid #0f0;

          &:hover {
            opacity: .6;
          }
        }

        // h4 {
        //   @include type-layout(xl, 1);
        //   margin-top: 0;
        //   margin-bottom: 1em;
        //   font-weight: normal;
        //   // color: rgba(198, 66, 0, 1);

        //   @include zen-respond-to(xxxs) {
        //     font-size: 3.5vw; //6vw
        //     line-height: 100%;
        //     margin-bottom: 0;
        //   }
        //   @include zen-respond-to(xs) {
        //     font-size: 3vw;
        //   }
        //   @include zen-respond-to(m) {
        //     font-size: 2.5vw;
        //   }
        //   @include zen-respond-to(xl) {
        //     @include type-layout(xl, 1);
        //     margin-bottom: 1em;
        //   }
        // }

        // p {
        //   @include type-layout(l, 1.25);
        //   margin-bottom: 0;
        //   font-family: $open-sans;

        //   @include zen-respond-to(xxxs) {
        //     font-size: 2.5vw; //6vw
        //     line-height: 150%;
        //   }
        //   @include zen-respond-to(xs) {
        //     //
        //   }
        //   @include zen-respond-to(m) {
        //     font-size: 2vw; //6vw
        //   }
        //   @include zen-respond-to(xl) {
        //     @include type-layout(l, 1.25);
        //   }
        // }
      }
    }

    .image-gallery__nav-overlay {
      ul {
        width: 45%;
        margin: 1em 0 2em;

        @include zen-respond-to(xxxs) {
          display: none;
        }
        /*@include zen-respond-to(xs) {
          display: block;
          width: 70vw;
        }*/
        @include zen-respond-to(m) {
          display: block;
          width: 60vw;
        }
        @include zen-respond-to(xl) {
          width: 45%;
          margin-bottom: 2em;
        }

        // li.active a {
        //   background: rgba(198, 66, 0, 1);
        // }
      }
    }
  }
}

/*** Mobile navigation drawer ***/
/* Most styles only appear with the phone breakpoint */
#drawer-toggle {
  position: absolute;
  opacity: 0;
}

#drawer-toggle-label {
  //display: none;

  //@include zen-respond-to('xxxs') {

    @include transition-default();

    //background: rgba(255, 255, 255, .5);

    //-webkit-touch-callout: none;
    -webkit-user-select: none;
    //-khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    right: 0;
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    z-index: 900;
  //}

  &:focus {
    border: 1px solid #fff;
  }

  &:hover {
    opacity: .5;
  }

  @include zen-respond-to('xs') {
    display: none;
  }

  span {
    display: none;
  }
}

/* adds our "hamburger" menu icon */
#drawer-toggle-label:before {
  @include zen-respond-to('xxxs') {
    content: '';
    display: block;
    position: absolute;
    height: 50px;
    width: 50px;
    left: 0;
    top: 0;
    z-index: 1100;
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%3E%3Crect%20width%3D%2230%22%20height%3D%2230%22%20rx%3D%227%22%20ry%3D%227%22%20fill%3D%22%230858ab%22%2F%3E%3Cline%20x1%3D%225%22%20y1%3D%2210%22%20x2%3D%2225%22%20y2%3D%2210%22%20stroke%3D%22%23fff%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221.8%22%2F%3E%3Cline%20x1%3D%225%22%20y1%3D%2215%22%20x2%3D%2225%22%20y2%3D%2215%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221.8%22%2F%3E%3Cline%20x1%3D%225%22%20y1%3D%2220%22%20x2%3D%2225%22%20y2%3D%2220%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221.8%22%2F%3E%3C%2Fsvg%3E');

    background-position: center center;
    background-repeat: no-repeat;

    -webkit-filter: drop-shadow( 3px 3px 3px rgba(0, 0, 0, .5) );
    filter: drop-shadow( 3px 3px 3px rgba(0, 0, 0, .5) );
  }
  @include zen-respond-to('xs') {
    display: none;
  }
}

/* Checked styles (drawer menu open state) */
#drawer-toggle:checked ~ #drawer-toggle-label {
  //height: 100%;
  //width: 100%-$drawer-width;
  //left: 0;
  /*right: 60px;*/

  //background: rgba(0, 0, 0, .1);

  /* Change menu to close image */
  &:before {
    content: '';

    top: 0;
    left: 0; /* 62% */
    width: 50px;
    height: 50px;
    font-size: 2rem;
    font-family: $arial;
    text-align: right;

    color: color('white');
    box-shadow: none;

    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%3E%3Crect%20width%3D%2230%22%20height%3D%2230%22%20rx%3D%227%22%20ry%3D%227%22%20fill%3D%22%230858ab%22%2F%3E%3Cline%20x1%3D%227.9%22%20y1%3D%227.9%22%20x2%3D%2222.1%22%20y2%3D%2222.1%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221.8%22%2F%3E%3Cline%20x1%3D%227.9%22%20y1%3D%2222.1%22%20x2%3D%2222.1%22%20y2%3D%227.9%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221.8%22%2F%3E%3C%2Fsvg%3E');
    background-position: center center;
    background-repeat: no-repeat;

    -webkit-filter: drop-shadow( 3px 3px 3px rgba(0, 0, 0, .5) );
    filter: drop-shadow( 3px 3px 3px rgba(0, 0, 0, .5) );
  }
}

// #drawer-toggle:checked ~ .layout-center {
//   margin-left: -$drawer-width;
//   max-width: 100%;

//   /*.header__drawer {
//     right: 0;
//   }*/
// }

.header__drawer {

  @include transition-default();
  //border: 1px solid #0f0;

  display: none;

  z-index: 10; // 100;

  position: static; // fixed;
  top: 0;
  //right: -($drawer-width+12%);
  height: auto; // 100%;
  width: auto; // $drawer-width;
  //background: color('blue');
  //overflow-x: hidden;
  //overflow-y: hidden;
  /*padding: 20px;*/
  //-webkit-overflow-scrolling: touch;

  //box-shadow: 0 0 75px color('grey-light');
  background: color('white');

  @include zen-respond-to('xs') {
    z-index: 0;

    display: block;

    position: static;
    top: auto;
    right: auto;
    height: auto;
    width: auto;
    background: none;
    overflow-x: show;
    overflow-y: show;
    box-shadow: none;
  }
}

#drawer-toggle:checked ~ .header {
  //position: absolute;
  //margin-left: -$drawer-width;
  //margin-right: $drawer-width;

  .header__drawer {
    display: block;

    .header__navigation {
      margin-top: 70px;
    }
  }

  .header__image-container,
  .header__alert {
    margin-top: 0;
  }
}

/* Disable admin-menu drop shadow which overlays top navigation bar */
#admin-menu {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Handle logged in admin-menu margin based on responsive design */
html body.admin-menu {
  margin-top: 29px !important;

  @include zen-respond-to('xxxs') {
    margin-top: 77px !important;
  }
  @include zen-respond-to('xxs') {
    margin-top: 53px !important;
  }
  @include zen-respond-to('l') {
    margin-top: 29px !important;
  }

  @media print {
    margin-top: 0 !important;
  }
}
