// sass-lint:disable indentation, no-color-keywords

// Colors
//
// Use the `color()` function to add colors to CSS properties. To learn more,
// [read the Chroma documentation](http://johnalbin.github.io/chroma/).
//
// Markup: chroma.twig
//
// Weight: -1
//
// Style guide: sass.colors

// Define the default color scheme's color names.
$chroma: define-default-color-scheme('branding', 'The site\'s main colors. Can be used to define colors in other color schemes.');

$chroma: add-colors((
  black:                  #000,
  grey-darkest:           ('black' lighten 20%), //
  grey-dark:              #575757, // ('black' lighten 40%), // #666
  'grey':                 ('black' lighten 60%), // #999
  grey-light:             ('black' lighten 80%), // #ccc
  grey-extra-light:       ('black' lighten 93.33%), // #eee
  grey-lightest:          ('black' lighten 95%),
  white:                  #fff,

  'blue':                 #004c97, // #0057b8, // #0858ab,  //#0072b9, 2945 C = 004C97, 2935 C = 0057b8
  light-blue:             #8ec5f6,
  lightest-blue:          ('light-blue' lighten 15%),
  darkest-blue:           #263041,
  gradient-blue-top:      #1f6aa5,
  gradient-blue-bottom:   #005796,
  gradient-blue-top-hover:      ('gradient-blue-top' lighten 10%),
  gradient-blue-bottom-hover:   ('gradient-blue-bottom' lighten 10%),
  red:                    #c00,
  yellow:                 #fd0,

  off-white:              #f0edeb,
  off-white-dark:         ('off-white' darken 5%)
));

// Define color names for functional uses.
$chroma: define-color-scheme('functional', 'Colors used by functional parts of the design.');
$chroma: add-colors('functional', (
  // Colors used in the main content area.
  text:                   #584b48,
  text-light:             #655553, // ('text' lighten 10%),
  text-lighter:           ('text' lighten 40%),
  text-bg:                'white',

  link:                   'blue',
  link-visited:           'blue', // ('blue' darken 20%),
  link-active:            'red',
  link-dark:              ('text' darken 50%), //('blue' darken 5%),
  link-light:             'light-blue',

  cta-blue:               'blue', // gradient-blue-bottom',
  cta-blue-hover:         ('cta-blue' lighten 20%),
  cta-blue-arrow:         #094783,
  cta-yellow:             #f1b434,

  campaign-blue:          'blue', // #004c97, // #0072c2,
  campaign-blue-light:    #2b79b1, //#338ece, // 'campaign-blue',
  campaign-blue-dark:     ('blue' darken 5%), // 'blue',

  red-mountain:           #bb133e, //#7f0003,

  alumni-green:           #c0be46,
  alumni-dark-blue:       #004c81,
  alumni-yellow:          #b8b63c,

  callout:                'off-white',

  border:                 #d6d4d1, //'grey-light', d6d4d1, f0f0f0
  border-print:           'grey',
  border-footer:          #3e485c,

  open:                   #cfc, // light green
  open-font:              ('open' darken 70%),
  closed:                 #fcc, // light red
  closed-font:            ('closed' darken 70%),

  autocomplete:           'text',
  autocomplete-bg:        'text-bg',
  autocomplete-select:    'white',
  autocomplete-select-bg: 'blue',

  body-bg:                #f8f5f2,
  logo-bg:                #ebebeb,
  menu-bg-active:         'grey-extra-light', // #f5f5f5,

  button:                 'text',
  button-disabled:        'grey',

  fieldset-summary:       'grey',
  form-error:             'red',

  mark-highlight:         'red',
  mark-bg:                'yellow',

  menu-active:            'text',

  preview-bg:             ('yellow' lighten 43%),

  progress-bar:           'blue',
  progress-bar-bg:        'grey-light',
  progress-bar-border:    'grey-dark',

  resizable-grippie:      'grey-extra-light',

  row-stripe:             'grey-extra-light',
  row-disabled:           'grey-light',

  skip-link:              'white',
  skip-link-bg:           'grey-darkest', // #575757, // 'grey-dark',

  status:                 'blue',
  status-bg:              (status lighten 62%),
  status-highlight:       text,
  warning:                text,
  warning-bg:             ('yellow' lighten 45%),
  warning-border:         'yellow',
  error:                  'red',
  error-bg:               (error lighten 57%),

  tabs-border:            'border',
  tabs-bg:                'text-bg',
  tab:                    'text',
  tab-text-shadow:        'white',
  tab-bg:                 ('grey-light' lighten 6.66%),
  tab-secondary:          'grey-dark',

  table-drag:             ('yellow' lighten 43%),
  table-select:           ('yellow' lighten 47%),

  watermark:              'blue',

  campaign-color-1:       #a36a11,
  campaign-color-2:       #125687,
  campaign-color-3:       #5d8035,
  campaign-color-4:       #4d3069,
  campaign-color-5:       #aa113f,

  front-tab-main:         #263041,
  front-tab-dark:         #181e28,
  front-tab-main-active:  #3c4554,
  front-tab-dark-active:  #272727,
  front-tab-link-alt:     #3cf,
));

$chroma-active-scheme: 'functional';
